<template>
  <div class="management">
    <div class="table-mask" v-if="spinning">
      <div class="table-mask-time">
        <a-spin size="large" />
        同步中...
      </div>
    </div>
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="实例列表">
        <div class="searchCondition">
          <div class="searchConditionItem">
            名称：
            <a-input
              placeholder="请输入实例名称"
              v-model.trim="dataBaseName"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            IP地址：
            <a-input
              placeholder="请输入ip地址"
              v-model.trim="host"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            状态：
            <a-select
              v-model="reachable"
              placeholder="请选择状态"
              allowClear
              style="width: 240px"
            >
              <a-select-option v-for="i in reachableList" :key="i.value">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addDatabase">添加实例</a-button>
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(columnsHost)"
          :columns="columnsHost"
          :dataSource="dataSourceHostList"
          :pagination="paginationHost"
          :loading="loadingTable"
          size="small"
        >
          <span slot="host" slot-scope="text, record" class="slotCell">
            <a-tooltip>
              <template slot="title"> {{ text }} </template>
              <span class="content">{{ text }}</span>
            </a-tooltip>
            <a-tooltip v-if="record.monitorSlaveDelay">
              <template slot="title"> 延迟监控已开启 </template>
              <img
                src="../assets/yanchi.png"
                alt=""
                width="15px"
                style="margin-left: 10px"
              />
            </a-tooltip>
            <a-tooltip v-if="!record.reachable">
              <template slot="title"> 已断开连接 </template>
              <img
                src="../assets/dklj.png"
                alt=""
                width="15px"
                style="margin-left: 10px"
              />
            </a-tooltip>
          </span>
          <span slot="name" slot-scope="text" style="width: 100%">
            {{ text }}
          </span>
          <span
            slot="lastSyncDate"
            slot-scope="text, record"
            style="width: 100%"
          >
            <span :class="record.color ? 'isRed' : ''">{{ text }}</span>
          </span>
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a-button
              type="link"
              size="small"
              :loading="record.loading"
              @click="newAccountList(record)"
              >账号列表</a-button
            >
            <a-divider type="vertical" />
            <a href="javascript:;" @click="syncInfo(record)">同步信息</a>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" href="#">
                其他操作 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a type="link" size="small" @click="accountList(record)"
                    >账号列表(旧)</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="empowerManage(record)"
                    >授权管理</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="toUpdateClick(record)">更新</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="left"
                    okText="确认"
                    cancelText="取消"
                    @confirm="deleteDatabase(record)"
                  >
                    <template slot="title">
                      是否删除实例 <b>{{ record.name }}</b> ？
                    </template>
                    <a href="javascript:;" style="color: #ff4d4f">删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="账号列表" force-render>
        <router-view name="second" v-if="activeKey == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="库列表" force-render>
        <router-view name="second" v-if="activeKey == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="授权列表" force-render>
        <router-view name="second" v-if="activeKey == '4'" />
      </a-tab-pane>
    </a-tabs>

    <!-- 授权管理 -->
    <empower-manage
      ref="empowerManage"
      :instanceId="instanceId"
      :databaseType="databaseType"
      @submit="empowerManageOk"
      @cancel="empowerManageCancel"
    >
    </empower-manage>
    <!-- 添加实例 -->
    <a-modal
      title="添加实例"
      v-model="addDatabaseShow"
      :maskClosable="false"
      :afterClose="closeAddDatabase"
    >
      <a-form>
        <a-form-item
          label="数据库类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="addDatabaseForm.databaseType"
            placeholder="请选择数据库类型"
            allowClear
            @change="dataTypeChange"
          >
            <a-select-option v-for="i in dataTypeList" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="IP地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入IP地址" v-model="addDatabaseForm.host" />
        </a-form-item>
        <a-form-item
          label="内网地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入内网地址"
            v-model="addDatabaseForm.innerHost"
          />
        </a-form-item>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入名称" v-model="addDatabaseForm.name" />
        </a-form-item>
        <a-form-item
          label="端口号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addDatabaseForm.port" />
        </a-form-item>
        <a-form-item
          label="超级账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="dataType !== 'REDIS'"
        >
          <a-input placeholder="必填项" v-model="addDatabaseForm.userName" />
        </a-form-item>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="必填项"
            v-model="addDatabaseForm.rootPassword"
          />
          <a
            v-if="dataType == 'MYSQL'"
            href="javascript:;"
            @click="rootPasswordAdd"
            class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="addDatabaseForm.remark"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex">
          <a-button
            type="primary"
            :loading="connectionLoading"
            @click="checkConnection"
            >测试连接</a-button
          >
          <a-button
            key="back"
            style="margin-left: auto"
            @click="addDatabaseShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :disabled="contentDisabled"
            :loading="loading"
            @click="addDatabaseOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新增账号  -->
    <a-modal
      :title="'新增账号 (' + exampleName + ')'"
      v-model="visibleAdd"
      :maskClosable="false"
      :afterClose="closeAdd"
      width="600px"
    >
      <a-form>
        <a-form-item
          label="IP地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="必填项" v-model="actionHost" disabled />
        </a-form-item>
        <a-form-item
          label="端口号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="必填项" v-model="actionForm.port" disabled />
        </a-form-item>
        <a-form-item
          label="系统账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-radio-group v-model="actionForm.systemAccount">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="!actionForm.systemAccount && !roleShow"
        >
          <a-select
            v-model="actionForm.dbName"
            @change="handleChangeDbName"
            @focus="getShowDatabases"
            placeholder="请选数据库"
            showSearch
          >
            <a-select-option
              v-for="(item, index) in databaseList"
              :key="item"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a href="javascript:;" @click="addCustomer" class="add_Customer"
            >添加数据库</a
          >
        </a-form-item>
        <a-form-item
          label="账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="actionForm.username" />
        </a-form-item>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="actionForm.password" />
          <a href="javascript:;" @click="randomPswAdd" class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item
          label="host:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="actionForm.allowHost" />
          <a-checkbox
            :checked="localHost"
            @change="localHostChange"
            class="randomLocalHost"
          >
            本机
          </a-checkbox>
        </a-form-item>
        <a-form-item
          label="访问方式:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-radio-group v-model="actionForm.innerAccess">
            <a-radio :value="false">外网</a-radio>
            <a-radio :value="true">内网</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          label="权限:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-radio-group v-model="actionForm.privilege">
            <a-radio value="all">all</a-radio>
            <a-radio value="readonly">只读</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAdd = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="'新增账号 (' + exampleName + ')'"
      v-model="visibleAddNew"
      :maskClosable="false"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="账号:" required>
          <a-input v-model="actionForm.user" />
        </a-form-item>
        <a-form-item label="密码:" required>
          <a-input v-model="actionForm.password" />
          <a href="javascript:;" @click="randomPswAdd" class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item label="allowHost:" required>
          <a-input v-model="actionForm.allowHost" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAddNew = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addOkNew"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改host  -->
    <a-modal
      title="修改host"
      v-model="visibleChangeHost"
      :maskClosable="false"
      :afterClose="closeChangeHost"
    >
      <a-form>
        <a-form-item
          label="host:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入host" v-model="newHost" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleChangeHost = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="changeHostOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 账号列表 -->
    <a-modal
      :title="'旧账号列表 ( ' + exampleName + ' )'"
      v-model="accountListShow"
      :maskClosable="false"
      :afterClose="closeAccountList"
      :footer="null"
      width="1100px"
      class="account-modal"
    >
      <a-tabs v-model="accountTabsKey" @change="callbackAccount">
        <a-button
          slot="tabBarExtraContent"
          type="primary"
          @click="addAccount"
          icon="plus"
          >新增账号</a-button
        >
        <a-tab-pane tab="普通账号" key="ordinary">
          <div class="searchCondition">
            <div class="searchConditionItem">
              用户：
              <a-input
                placeholder="请输入用户"
                v-model.trim="username"
                @pressEnter="getDbList(1)"
                allowClear
                @change="allowClearXyChange"
              />
            </div>
            <div class="searchConditionItem">
              x：
              <a-input
                placeholder="请输入x"
                v-model.trim="x"
                @pressEnter="getDbList(1)"
                allowClear
                @change="allowClearXyChange"
                style="width: 100px"
              />
            </div>
            <div class="searchConditionItem">
              y：
              <a-input
                placeholder="请输入y"
                v-model.trim="y"
                @pressEnter="getDbList(1)"
                allowClear
                @change="allowClearXyChange"
                style="width: 100px"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="getDbList(1)" icon="search"
                >查询</a-button
              >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="batchDeleteAccount()"
                :disabled="accountSelectedRowKeys.length == 0"
              >
                <template slot="title">确认是否批量删除</template>
                <a-button
                  type="danger"
                  icon="delete"
                  :disabled="accountSelectedRowKeys.length == 0"
                  >批量删除</a-button
                >
              </a-popconfirm>
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(ordinaryColumns)"
            :columns="ordinaryColumns"
            :dataSource="dataSource"
            :pagination="pagination"
            size="small"
            :rowKey="(record) => record.id"
            :row-selection="{
              onChange: onAccountSelectChange,
              selectedRowKeys: accountSelectedRowKeys,
            }"
          >
            <span slot="innerAccess" slot-scope="text" style="width: 100%">
              <span v-if="text">内网</span>
              <span v-if="!text">外网</span>
            </span>
            <span slot="privilege" slot-scope="text" style="width: 100%">
              <span v-if="text === 'all'">all</span>
              <span v-if="text === 'readonly'">只读</span>
            </span>
            <span slot="dbName" slot-scope="text, record" style="width: 100%">
              <span>{{ text }}</span>
              <a-tooltip v-if="record.accountLocked">
                <template slot="title"> 账号已禁用 </template>
                <a-icon
                  type="stop"
                  style="margin: 5px; color: #f56c6c; font-size: 12px"
                />
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a href="javascript:;" @click="checkPassword(record)">复制密码</a>
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  复制信息 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="copyInfo(record)"
                      >密码信息</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="copyCoordinate(record)"
                      >坐标信息</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  其他操作 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-popconfirm
                      placement="right"
                      okText="确认"
                      cancelText="取消"
                      @confirm="changePassword(record)"
                    >
                      <template slot="title">是否修改密码</template>
                      <a href="javascript:;">修改密码</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="changeHost(record)"
                      >修改host</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="disabledClick(record)">
                      <span v-if="!record.accountLocked">禁用</span>
                      <span v-else>启用</span>
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="inspect(record)">检查</a>
                  </a-menu-item>
                  <a-menu-item v-if="roleShow">
                    <a href="javascript:;" @click="bindingRole(record)"
                      >角色绑定</a
                    >
                  </a-menu-item>
                  <a-menu-item class="delete-btn">
                    <a
                      href="javascript:;"
                      @click="deleteData(record)"
                      :disabled="record.username === 'root'"
                      class="delete-a"
                      :class="record.username === 'root' ? 'disabled' : ''"
                      >删除</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="系统账号" key="system">
          <div class="searchCondition">
            <div class="searchConditionItem">
              x：
              <a-input
                placeholder="请输入x"
                v-model.trim="x"
                @pressEnter="getDbList(1)"
                allowClear
                @change="allowClearXyChange"
              />
            </div>
            <div class="searchConditionItem">
              y：
              <a-input
                placeholder="请输入y"
                v-model.trim="y"
                @pressEnter="getDbList(1)"
                allowClear
                @change="allowClearXyChange"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="getDbList(1)" icon="search"
                >查询</a-button
              >
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(columns)"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="pagination"
            size="small"
          >
            <span slot="innerAccess" slot-scope="text" style="width: 100%">
              <span v-if="text">内网</span>
              <span v-if="!text">外网</span>
            </span>
            <span slot="privilege" slot-scope="text" style="width: 100%">
              <span v-if="text === 'all'">all</span>
              <span v-if="text === 'readonly'">只读</span>
            </span>
            <span slot="dbName" slot-scope="text, record" style="width: 100%">
              <span>{{ text }}</span>
              <a-tooltip v-if="record.accountLocked">
                <template slot="title"> 账号已禁用 </template>
                <a-icon
                  type="stop"
                  style="margin: 5px; color: #f56c6c; font-size: 12px"
                />
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a href="javascript:;" @click="checkPassword(record)">复制密码</a>
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  复制信息 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="copyInfo(record)"
                      >密码信息</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="copyCoordinate(record)"
                      >坐标信息</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  其他操作 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-popconfirm
                      placement="right"
                      okText="确认"
                      cancelText="取消"
                      @confirm="changePassword(record)"
                    >
                      <template slot="title">是否修改密码</template>
                      <a href="javascript:;">修改密码</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="changeHost(record)"
                      >修改host</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="disabledClick(record)">
                      <span v-if="!record.accountLocked">禁用</span>
                      <span v-else>启用</span>
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="inspect(record)">检查</a>
                  </a-menu-item>
                  <a-menu-item class="delete-btn">
                    <a
                      href="javascript:;"
                      @click="deleteData(record)"
                      :disabled="record.username === 'root'"
                      class="delete-a"
                      :class="record.username === 'root' ? 'disabled' : ''"
                      >删除</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="角色列表" key="role" v-if="roleShow">
          <div class="searchCondition">
            <div class="searchConditionItem">
              用户：
              <a-input
                placeholder="请输入用户"
                v-model.trim="searchRoleName"
                @pressEnter="searchRoleList()"
                allowClear
                @change="allowClearSearchRoleChange"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="addRole()" icon="plus"
                >新增</a-button
              >
              <a-button type="primary" @click="autoCompleteRole()"
                >自动补全角色</a-button
              >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="batchDeleteRole()"
                :disabled="roleSelectedRowKeys.length == 0"
              >
                <template slot="title">确认是否批量删除</template>
                <a-button
                  type="danger"
                  icon="delete"
                  :disabled="roleSelectedRowKeys.length == 0"
                  >批量删除</a-button
                >
              </a-popconfirm>
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(roleColumns)"
            :columns="roleColumns"
            :dataSource="roleList"
            :pagination="false"
            size="small"
            :rowKey="(record) => record.role"
            :row-selection="{
              onChange: onRoleSelectChange,
              selectedRowKeys: roleSelectedRowKeys,
            }"
          >
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteRole(record)"
              >
                <template slot="title">确认是否删除{{ record.role }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <a-modal
      :title="'账号列表 ( ' + exampleName + ' )'"
      v-model="newAccountListShow"
      :maskClosable="false"
      :afterClose="closeAccountList"
      :footer="null"
      width="1100px"
      class="account-modal"
    >
      <a-tabs v-model="accountTabsKey" @change="callbackAccountNew">
        <a-tab-pane tab="普通账号" key="ordinary">
          <div class="searchCondition">
            <div class="searchConditionItem">
              用户：
              <a-input
                placeholder="请输入用户"
                v-model.trim="username"
                @pressEnter="searchAccountList()"
                allowClear
                @change="allowClearSearchAccChange"
              />
            </div>
            <div class="searchConditionItem">
              x：
              <a-input
                placeholder="请输入x"
                v-model.trim="x"
                @pressEnter="searchAccountList()"
                allowClear
                @change="allowClearSearchAccChange"
                style="width: 100px"
              />
            </div>
            <div class="searchConditionItem">
              y：
              <a-input
                placeholder="请输入y"
                v-model.trim="y"
                @pressEnter="searchAccountList()"
                allowClear
                @change="allowClearSearchAccChange"
                style="width: 100px"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="getAccountList()" icon="search"
                >查询</a-button
              >
              <a-button type="primary" @click="addAccountNew" icon="plus"
                >新增账号</a-button
              >
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(newAccountColumns)"
            :columns="newAccountColumns"
            :dataSource="dataSource"
            size="small"
            :row-selection="{
              onChange: onAccountSelectChange,
              selectedRowKeys: accountSelectedRowKeys,
            }"
          >
            <span slot="user" slot-scope="text, record" style="width: 100%">
              {{ text }}@{{ record.allowHost }}
            </span>
            <span slot="xy" slot-scope="text, record" style="width: 100%">
              {{ text }}
              <a-tooltip>
                <template slot="title"> 绑定坐标 </template>
                <a-button
                  v-if="record.xy == '- / -'"
                  type="link"
                  icon="edit"
                  size="small"
                  @click="bindXY(record)"
                />
              </a-tooltip>
            </span>
            <span slot="superAccount" slot-scope="text" style="width: 100%">
              {{ text ? "是" : "否" }}
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a-button
                type="link"
                size="small"
                :disabled="record.xy == '- / -'"
                @click="checkPasswordNew(record, 1)"
                >复制密码</a-button
              >
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  复制信息 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button
                      type="link"
                      size="small"
                      :disabled="record.xy == '- / -'"
                      @click="checkPasswordNew(record, 2)"
                      >密码信息</a-button
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      type="link"
                      size="small"
                      :disabled="record.xy == '- / -'"
                      @click="checkPasswordNew(record, 3)"
                      >坐标信息</a-button
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-divider type="vertical" />
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  其他操作 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-popconfirm
                      placement="right"
                      okText="确认"
                      cancelText="取消"
                      @confirm="changePasswordNew(record)"
                    >
                      <template slot="title">是否修改密码</template>
                      <a href="javascript:;">修改密码</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" disabled @click="changeHost(record)"
                      >修改host</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      disabled
                      @click="disabledClick(record)"
                    >
                      {{ record.accountLocked ? "启用" : "禁用" }}
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="inspectNew(record)">检查</a>
                  </a-menu-item>
                  <a-menu-item v-if="roleShow">
                    <a href="javascript:;" @click="bindingRoleNew(record)"
                      >角色绑定</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :disabled="!record.accountExtraInfo"
                      @click="bindingUser(record)"
                      >绑定到用户</a
                    >
                  </a-menu-item>
                  <a-menu-item class="delete-btn">
                    <a
                      href="javascript:;"
                      :disabled="
                        !record.accountExtraInfo || record.superAccount
                      "
                      @click="dropUser(record)"
                      class="delete-a"
                    >
                      删除
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="角色列表" key="role" v-if="roleShow">
          <div class="searchCondition">
            <div class="searchConditionItem">
              用户：
              <a-input
                placeholder="请输入用户"
                v-model.trim="searchRoleName"
                @pressEnter="searchRoleList()"
                allowClear
                @change="allowClearSearchRoleChange"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="addRole()" icon="plus"
                >新增角色</a-button
              >
              <a-button type="primary" @click="autoCompleteRoleNew()"
                >自动补全角色</a-button
              >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="batchDeleteRoleNew()"
                :disabled="roleSelectedRowKeys.length == 0"
              >
                <template slot="title">确认是否批量删除</template>
                <a-button
                  type="danger"
                  icon="delete"
                  :disabled="roleSelectedRowKeys.length == 0"
                  >批量删除</a-button
                >
              </a-popconfirm>
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(roleColumns)"
            :columns="roleColumns"
            :dataSource="roleList"
            :pagination="false"
            size="small"
            :rowKey="(record) => record.role"
            :row-selection="{
              onChange: onRoleSelectChange,
              selectedRowKeys: roleSelectedRowKeys,
            }"
            :scroll="{ y: 600 }"
          >
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a href="javascript:;" @click="editGrant(record)">编辑权限</a>
              <a-divider type="vertical" />
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteRoleNew(record)"
              >
                <template slot="title">确认是否删除{{ record.role }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="数据库列表" key="database">
          <div class="searchCondition">
            <div class="searchConditionItem">
              数据库名称：
              <a-input
                placeholder="请输入数据库名称"
                v-model.trim="searchDatabaseName"
                @pressEnter="searchDatabaseList()"
                allowClear
                @change="allowClearSearchDatabaseChange"
              />
            </div>
            <div class="searchButton">
              <a-button
                type="primary"
                @click="showDatabaseForSuper()"
                icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="addDatabaseForSuper()"
                icon="plus"
                >新增数据库</a-button
              >
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(databaseColumns)"
            :columns="databaseColumns"
            :dataSource="databaseList"
            :pagination="false"
            size="small"
            :rowKey="(record) => record.value"
            :scroll="{ y: 600 }"
          >
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a
                href="javascript:;"
                style="color: #ff4d4f"
                @click="dropDatabaseForSuper(record)"
                >删除</a
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <!-- 更新 -->
    <a-modal
      title="更新"
      v-model="toUpdateShow"
      :maskClosable="false"
      :afterClose="toUpdateClose"
      width="400px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入名称" v-model="toUpdateForm.name" />
        </a-form-item>
        <a-form-item
          label="路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入路径" v-model="toUpdateForm.path" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="toUpdateForm.remark"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="toUpdateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="toUpdateOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 添加数据库 -->
    <a-modal
      title="添加数据库"
      v-model="add_customer_show"
      :maskClosable="false"
      :afterClose="addCustomerClose"
      width="400px"
    >
      <a-form>
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入数据库名" v-model="addCustomerName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="add_customer_show = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addCustomerOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="添加角色"
      v-model="addRoleShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="角色名称:">
          <a-input placeholder="请输入角色名称" v-model="roleName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addRoleShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addRoleSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="自动补全"
      v-model="autoCompleteRoleShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="autoCompleteRoleDbList"
            showSearch
            @focus="getShowDatabases"
            placeholder="请选数据库"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in databaseList"
              :key="item"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="autoCompleteRoleShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="autoCompleteRoleSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="自动补全"
      v-model="autoCompleteRoleNewShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="数据库名:">
          <a-select
            v-model="autoCompleteRoleDbList"
            showSearch
            placeholder="请选择数据库"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in databaseList"
              :key="item"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="autoCompleteRoleNewShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="autoCompleteRoleNewSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="'角色绑定' + bindingRoleUsernameLabel"
      v-if="bindingRoleShow"
      v-model="bindingRoleShow"
      :maskClosable="false"
      width="800px"
    >
      <a-transfer
        :data-source="bindingRoleList"
        :target-keys="bindingTargetRoleKeys"
        :selected-keys="bindingSelectRoleKeys"
        :render="(item) => item.role"
        :listStyle="{ width: '47%', height: '300px' }"
        showSearch
        @change="handleChange"
        @selectChange="handleSelectChange"
      />
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindingRoleShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="bindingRoleSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="绑定坐标"
      v-model="bindXYShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item required label="账号:">
          <a-input placeholder="请输入账号" v-model="bindXYData.user" />
        </a-form-item>
        <a-form-item required label="allowHost:">
          <a-input
            placeholder="请输入allowHost"
            v-model="bindXYData.allowHost"
          />
        </a-form-item>
        <a-form-item required label="密码:">
          <a-input placeholder="请输入密码" v-model="bindXYData.password" />
        </a-form-item>
        <a-form-item required label="唯一key:">
          <a-input placeholder="请输入唯一key" v-model="bindXYData.uniqueKey" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindXYShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="bindXYSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="编辑权限"
      v-model="editGrantShow"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          数据库：
          <a-select
            v-model="databaseName"
            showSearch
            placeholder="请选择数据库"
            @change="showTablesForSuper"
          >
            <a-select-option
              v-for="(item, index) in databaseList"
              :key="item"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchConditionItem">
          表：
          <a-select
            v-model="tableName"
            showSearch
            placeholder="请选择表"
            :disabled="!databaseName"
            @change="listRolePriviledges"
          >
            <a-select-option
              v-for="(item, index) in tableList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div style="font-size: 12px; margin-bottom: 10px; font-weight: 500">
        授权对象: {{ databaseName == "" ? "*" : databaseName }} ·
        {{ tableName == "" ? "*" : tableName }}
      </div>
      <a-transfer
        :data-source="allGrantOptionList"
        :target-keys="grantTargetKeys"
        :selected-keys="grantSelectKeys"
        :render="(item) => item.label"
        :listStyle="{ width: '47.9%', height: '300px' }"
        showSearch
        @change="grantOptionChange"
        @selectChange="grantOptionSelectChange"
      />
      <div style="font-size: 12px; margin-top: 5px; font-weight: 500">
        全部权限
      </div>
      <a-textarea v-model="plainGrants" :rows="5" style="margin-top: 5px" />
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editGrantShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="editGrantSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="添加授权"
      v-model="bindUserShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="用户:">
          <a-select
            v-model="bindingUserData.userLoginName"
            placeholder="搜索用户"
            showSearch
            :filterOption="false"
            @search="getUserNameList"
          >
            <a-select-option
              v-for="item in userNameList"
              :key="item.userLoginName"
              >{{ item.userName }}({{ item.userLoginName }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="实例:">
          <a-select v-model="bindingUserData.instanceId" disabled>
            <a-select-option
              v-for="item in instanceList"
              :key="item.id"
              :value="item.id"
              >{{ item.host + ":" + item.port }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="账号:">
          <a-select v-model="bindingUserData.accountExtraInfoId" disabled>
            <a-select-option
              v-for="item in dataSource"
              :key="item.id"
              :value="item.id"
              >{{ item.user + "@" + item.allowHost }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="授权过期时间:">
          <a-switch
            un-checked-children="永不过期"
            checked-children="定时过期"
            v-model="expireTimeShow"
            @change="expireTimeShowChange"
            class="expireTimeSwitch"
          />
          <a-input
            v-model="expireTime"
            v-if="expireTimeShow"
            placeholder="请输入过期时间"
            style="width: 200px; margin-left: 5px"
          >
            <a-select
              slot="addonAfter"
              v-model="expireUnit"
              style="width: 65px"
            >
              <a-select-option value="月"> 月 </a-select-option>
              <a-select-option value="天"> 天 </a-select-option>
              <a-select-option value="小时"> 小时 </a-select-option>
              <a-select-option value="分钟"> 分钟 </a-select-option>
              <a-select-option value="秒"> 秒 </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindUserShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="bindingUserSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="updateDatabaseForSuperTitle"
      v-model="updateDatabaseForSuperShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <div
          style="padding-left: 30px; margin-bottom: 10px; color: red"
          v-if="updateDatabaseForSuperTitle == '删除数据库'"
        >
          确认是否删除 &nbsp;&nbsp;{{
            dropDatabaseName
          }}，请输入数据库名称以确认
        </div>
        <a-form-item label="数据库名称:">
          <a-input placeholder="请输入数据库名称" v-model="databaseName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateDatabaseForSuperShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="updateDatabaseForSuperSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import empowerManage from "./empowerManage.vue";
import * as api from "../lib/databaseList.js";
import { grant2User } from "../lib/userAccountRelation.js";

export default {
  name: "databaseList",
  components: {
    empowerManage,
  },
  data() {
    return {
      username: "",
      x: "",
      y: "",
      exampleName: "",
      localHost: false,
      activeKey: "1",
      innerHost: "",
      roleShow: false,
      addRoleShow: false,
      roleName: "",
      roleColumns: [
        {
          title: "角色",
          dataIndex: "role",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      allRoleList: [],
      roleList: [],
      searchRoleName: "",
      dataBaseName: "",
      reachable: undefined,
      reachableList: [
        {
          value: "true",
          label: "正常",
        },
        {
          value: "false",
          label: "已断开",
        },
      ],
      dataTypeList: [
        {
          value: "REDIS",
          label: "redis",
        },
        {
          value: "MYSQL",
          label: "mysql",
        },
        {
          value: "CLICKHOUSE",
          label: "clickhouse",
        },
      ],
      dataType: "MYSQL",
      toUpdateShow: false,
      toUpdateForm: {
        instanceId: "",
        name: "",
        path: "",
        remark: "",
      },
      spinning: false,
      accountTabsKey: "ordinary",
      systemAccount: false,
      databaseList: [],
      addDatabaseShow: false,
      addDatabaseForm: {
        host: "",
        port: "",
        innerDb: false,
        remark: "",
        rootPassword: "",
        innerHost: "",
        userName: "root",
        name: "",
        databaseType: undefined,
        instanceId: undefined,
      },
      accountListShow: false,
      newAccountListShow: false,
      pageNoBackup: 1,
      pageNoChildbackup: 1,
      weekTimeValue: null,
      monthTimeValue: null,
      weekStartTimeValue: null,
      monthStartTimeValue: null,
      columnsHost: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 150,
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "host",
          scopedSlots: { customRender: "host" },
          width: 400,
        },
        {
          title: "内网IP地址",
          ellipsis: true,
          dataIndex: "innerHost",
          width: 250,
        },
        {
          title: "端口号",
          ellipsis: true,
          dataIndex: "port",
          width: 90,
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "version",
          width: 90,
        },
        {
          title: "存储",
          ellipsis: true,
          dataIndex: "readableLength",
          width: 90,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "上次同步时间",
          ellipsis: true,
          dataIndex: "lastSyncDateLabel",
          scopedSlots: { customRender: "lastSyncDate" },
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 240,
        },
      ],
      columns: [
        {
          title: "数据库",
          ellipsis: true,
          dataIndex: "dbName",
          scopedSlots: { customRender: "dbName" },
          width: 200,
        },
        {
          title: "用户",
          ellipsis: true,
          dataIndex: "usernameLabel",
          width: 200,
        },
        {
          title: "坐标",
          ellipsis: true,
          dataIndex: "xy",
        },
        {
          title: "访问方式",
          ellipsis: true,
          dataIndex: "innerAccess",
          width: 80,
          scopedSlots: { customRender: "innerAccess" },
        },
        {
          title: "权限",
          ellipsis: true,
          dataIndex: "privilege",
          width: 80,
          scopedSlots: { customRender: "privilege" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 240,
          scopedSlots: { customRender: "action" },
        },
      ],
      ordinaryColumns: [],
      accountSelectedRows: [],
      accountSelectedRowKeys: [],
      dataSourceHostList: [],
      paginationHost: {},
      loadingTable: false,
      pageNoHost: 1,
      allDataSource: [],
      dataSource: [],
      pagination: {},
      host: "",
      dbName: "",
      needBackup: null,
      password: "",
      actionForm: {
        host: "",
        dbName: undefined,
        port: "3306",
        username: "",
        password: "",
        innerDb: false,
        allowHost: "%",
        privilege: "all",
        innerAccess: false,
        systemAccount: false,
      },
      instanceId: "",
      actionHost: "",
      visibleAdd: false,
      visibleAddNew: false,
      loading: false,
      newPassword: "",
      passwordType: "password",
      userId: "",
      pageNo: 1,
      pageSize: 10,
      id: "",
      taskId: "",
      databaseNameList: [],
      addCustomerName: "",
      add_customer_show: false,
      visibleChangeHost: false,
      newHost: "",
      databaseType: "",
      autoCompleteRoleDbList: [],
      autoCompleteRoleNewShow: false,
      autoCompleteRoleShow: false,
      roleSelectedRowKeys: [],
      bindingRoleList: [],
      bindingUserRoleKeys: [],
      bindingTargetRoleKeys: [],
      bindingSelectRoleKeys: [],
      bindingRoleShow: false,
      bindingRoleUsernameLabel: "",
      bindingRoleUser: "",
      bindingRoleAllowHost: "",
      connectionLoading: false,
      contentDisabled: false,
      newAccountColumns: [
        {
          title: "用户",
          ellipsis: true,
          dataIndex: "user",
          width: 200,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "坐标",
          ellipsis: true,
          dataIndex: "xy",
          scopedSlots: { customRender: "xy" },
        },
        {
          title: "是否为主账号",
          ellipsis: true,
          dataIndex: "superAccount",
          width: 150,
          scopedSlots: { customRender: "superAccount" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 240,
          scopedSlots: { customRender: "action" },
        },
      ],
      bindXYShow: false,
      bindXYData: {},
      allGrantOptionList: [],
      grantUserList: [],
      grantTargetKeys: [],
      grantSelectKeys: [],
      databaseName: undefined,
      searchDatabaseName: "",
      databaseColumns: [
        {
          title: "数据库名称",
          dataIndex: "label",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      databaseList: [],
      allDatabaseList: [],
      updateDatabaseForSuperTitle: "",
      updateDatabaseForSuperShow: false,
      dropDatabaseName: "",
      tableName: undefined,
      tableList: [],
      editGrantShow: false,
      plainGrants: "",
      expireTimeShow: false,
      expireUnit: "天",
      expireTime: 1,
      bindUserShow: false,
      bindingUserData: {},
      userNameList: [],
      instanceList: [],
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/databaseList") {
          this.activeKey = "1";
        } else if (newVal == "/databaseList/management") {
          this.activeKey = "2";
        } else if (newVal == "/databaseList/sequenceList") {
          this.activeKey = "3";
        } else if (newVal.indexOf("/databaseList/permissionList") !== -1) {
          this.activeKey = "4";
        }
      },
    },
    activeKey: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/databaseList") return;
          this.$router.replace("/databaseList");
        } else if (newVal === "2") {
          if (this.$route.path == "/databaseList/management") return;
          this.$router.replace("/databaseList/management");
        } else if (newVal === "3") {
          if (this.$route.path == "/databaseList/sequenceList") return;
          this.$router.replace("/databaseList/sequenceList");
        } else if (newVal === "4") {
          if (this.$route.path.indexOf("/databaseList/permissionList") !== -1)
            return;
          this.$router.replace("/databaseList/permissionList");
        }
      },
    },
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 数据库类型改变
    dataTypeChange(val) {
      this.dataType = val;

      if (this.dataType === "CLICKHOUSE") {
        this.addDatabaseForm.port = "8123";
        this.addDatabaseForm.userName = "default";
      } else {
        this.addDatabaseForm.port = "3306";
        this.addDatabaseForm.userName = "root";
        if (this.dataType === "REDIS") {
          this.addDatabaseForm.rootPassword = "";
        } else {
          this.rootPasswordAdd();
        }
      }
    },
    // 点击授权管理
    empowerManage(val) {
      this.instanceId = val.id;
      this.databaseType = val.databaseType;
      this.$refs.empowerManage.init();
    },
    // 确定授权管理
    empowerManageOk(data) {
      // console.log(data)
      this.query();
    },
    // 确定授权管理
    empowerManageCancel(data) {},
    // 点击账号列表修改host
    changeHost(val) {
      this.visibleChangeHost = true;
      this.newHost = val.allowHost;
      this.id = val.id;
    },
    // 提交账号列表修改host
    changeHostOk() {
      let data = {
        id: this.id,
        allowHost: this.newHost,
      };
      this.loading = true;
      api.dbChangeHost(data).then((res) => {
        this.loading = false;
        if (res.result === 200) {
          this.visibleChangeHost = false;
          this.$message.success("修改成功");
          if (this.newAccountListShow) {
            this.getAccountList();
          } else {
            this.getDbList(1);
          }
        }
      });
    },
    // 关闭账号列表修改host
    closeChangeHost() {
      this.loading = false;
      this.newHost = "";
    },
    // 点击账号列表禁用
    disabledClick(val) {
      let contentData = {
        数据库: val.dbName,
        登录名: val.username,
        host: val.allowHost,
      };
      let contentText = "";
      let title = "启用";
      if (!val.accountLocked) {
        title = "禁用";
      }
      for (let i in contentData) {
        if (contentData[i]) {
          contentText += i + ": " + contentData[i] + ",";
        }
      }
      let contentList = contentText.split(",");
      this.$confirm({
        title: title,
        content: (h) => (
          <div>
            {contentList[0]}
            <br />
            {contentList[1]}
            <br />
            {contentList[2]}
            <br />
            是否{title}该账号？
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          if (val.accountLocked) {
            api.unlockAccount({ id: val.id }).then((res) => {
              if (res.result === 200) {
                this.$message.success("启用成功");
                if (this.newAccountListShow) {
                  this.getAccountList();
                } else {
                  this.getDbList(1);
                }
              }
            });
          } else {
            api.lockAccount({ id: val.id }).then((res) => {
              if (res.result === 200) {
                this.$message.success("禁用成功");
                if (this.newAccountListShow) {
                  this.getAccountList();
                } else {
                  this.getDbList(1);
                }
              }
            });
          }
        },
        onCancel() {},
      });
    },
    // 点击添加数据库
    addCustomer() {
      this.add_customer_show = true;
    },
    // 确定添加数据库
    addCustomerOk() {
      let data = {
        instanceId: this.instanceId,
        databaseName: this.addCustomerName,
      };
      api.addDatabaseForInstance(data).then((res) => {
        if (res.result === 200) {
          this.add_customer_show = false;
          this.$message.success("新增成功");
        }
      });
    },
    // 关闭添加数据库
    addCustomerClose() {
      this.addCustomerName = "";
    },
    // 点击更新
    toUpdateClick(val) {
      this.toUpdateShow = true;
      this.toUpdateForm = {
        instanceId: val.id,
        name: val.name,
        path: val.path,
        remark: val.remark,
      };
    },
    // 确定更新
    toUpdateOk() {
      api.updateBasicInfo(this.toUpdateForm).then((res) => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.toUpdateShow = false;
          this.query();
        }
      });
    },
    // 关闭更新
    toUpdateClose() {
      this.toUpdateForm = {
        instanceId: "",
        name: "",
        path: "",
        remark: "",
      };
    },
    // 点击删除--实例
    deleteDatabase(val) {
      api.deleteDase({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击账号列表tab
    callbackAccount(key) {
      this.x = "";
      this.y = "";
      if (key === "ordinary") {
        this.systemAccount = false;
        this.getDbList(1);
      } else if (key === "system") {
        this.systemAccount = true;
        this.getDbList(1);
      } else {
        this.getRoleList();
      }
    },
    callbackAccountNew(key) {
      if (key === "ordinary") {
        this.x = "";
        this.y = "";
        this.systemAccount = false;
        this.getAccountList();
      } else if (key == "role") {
        this.searchRoleName = "";
        this.getRoleListNew();
      } else if (key == "database") {
        this.showDatabaseForSuper();
      }
    },
    // 点击同步信息
    syncInfo(val) {
      this.spinning = true;
      api
        .syncDatabaseInfo({
          id: val.id,
        })
        .then((res) => {
          this.spinning = false;
          if (res.result === 200) {
            this.$message.success("信息同步成功");
            this.query();
          }
        })
        .catch((err) => {
          this.spinning = false;
          this.query();
        });
    },
    // 点击新增实例
    addDatabase() {
      this.contentDisabled = true;
      this.addDatabaseShow = true;
      this.addDatabaseForm.databaseType = "MYSQL";
      this.addDatabaseForm.port = "3306";
      this.rootPasswordAdd();
    },
    checkConnection() {
      let data = {
        host: this.addDatabaseForm.host,
        port: this.addDatabaseForm.port,
        userName: this.addDatabaseForm.userName,
        rootPassword: this.addDatabaseForm.rootPassword,
        innerHost: this.addDatabaseForm.innerHost,
        remark: this.addDatabaseForm.remark,
        name: this.addDatabaseForm.name,
        databaseType: this.addDatabaseForm.databaseType,
        instanceId: this.addDatabaseForm.instanceId,
      };
      if (!this.addDatabaseForm.host) {
        this.$message.warning("ip地址不能为空！");
        return;
      }
      if (!this.addDatabaseForm.innerHost) {
        this.$message.warning("内网地址不能为空！");
        return;
      }
      if (!this.addDatabaseForm.port) {
        this.$message.warning("端口号不能为空！");
        return;
      }
      if (this.dataType === "MYSQL") {
        if (!this.addDatabaseForm.userName) {
          this.$message.warning("超级账号不能为空！");
          return;
        }
      }
      if (!this.addDatabaseForm.rootPassword) {
        this.$message.warning("root密码不能为空！");
        return;
      }

      this.connectionLoading = true;
      api
        .checkConnection(data)
        .then((res) => {
          if (res.result === 200) {
            this.connectionLoading = false;
            if (res.data.success) {
              this.$message.success("连接成功");
              this.contentDisabled = false;
            } else {
              this.$message.error("连接失败:" + res.data.errorMessage);
            }
          }
        })
        .catch((err) => {
          this.connectionLoading = false;
        });
    },
    // 确定新增实例
    addDatabaseOk() {
      let data = {
        host: this.addDatabaseForm.host,
        port: this.addDatabaseForm.port,
        userName: this.addDatabaseForm.userName,
        rootPassword: this.addDatabaseForm.rootPassword,
        innerHost: this.addDatabaseForm.innerHost,
        remark: this.addDatabaseForm.remark,
        name: this.addDatabaseForm.name,
        databaseType: this.addDatabaseForm.databaseType,
        instanceId: this.addDatabaseForm.instanceId,
      };
      if (!this.addDatabaseForm.host) {
        this.$message.warning("ip地址不能为空！");
        return;
      }
      if (!this.addDatabaseForm.innerHost) {
        this.$message.warning("内网地址不能为空！");
        return;
      }
      if (!this.addDatabaseForm.port) {
        this.$message.warning("端口号不能为空！");
        return;
      }
      if (this.dataType === "MYSQL") {
        if (!this.addDatabaseForm.userName) {
          this.$message.warning("超级账号不能为空！");
          return;
        }
      }
      if (!this.addDatabaseForm.rootPassword) {
        this.$message.warning("root密码不能为空！");
        return;
      }

      this.loading = true;
      api
        .addDatabaseHost(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.addDatabaseShow = false;
            this.$message.success("新增成功");
            this.query(1);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭新增实例
    closeAddDatabase() {
      this.addDatabaseForm = {
        host: "",
        port: "",
        innerDb: false,
        remark: "",
        rootPassword: "",
        innerHost: "",
        userName: "root",
        type: undefined,
        name: "",
        databaseType: undefined,
        handleType: undefined,
        instanceId: undefined,
      };
    },
    // 查询数据库名称
    getShowDatabases() {
      let data = {
        instanceId: this.instanceId,
      };
      api.showDatabaseForSuper(data).then((res) => {
        if (res.result === 200) {
          res.data.result.forEach((databaseName) => {
            this.databaseList.push({
              label: databaseName,
              value: databaseName,
            });
          });
        }
      });
    },
    // 选择数据库名称
    handleChangeDbName(val) {
      this.actionForm.dbName = val;
      this.actionForm.username = val;
    },
    // 点击账号列表
    accountList(val) {
      this.exampleName = val.name + "," + val.version;
      this.actionForm.host = val.host;
      this.actionHost = val.host;
      this.actionForm.port = val.port;
      this.instanceId = val.id;
      this.innerHost = val.innerHost;
      this.roleShow = val.version.startsWith("8");
      this.ordinaryColumns.splice(0);
      this.ordinaryColumns.push(...this.columns);
      if (this.roleShow) {
        this.ordinaryColumns.splice(0, 1);
      }
      this.getDbList(1);
      setTimeout(() => {
        this.accountListShow = true;
      }, 100);
    },
    newAccountList(val) {
      this.exampleName = val.name + "," + val.version;
      this.actionForm.host = val.host;
      this.actionHost = val.host;
      this.actionForm.port = val.port;
      this.instanceId = val.id;
      this.innerHost = val.innerHost;
      if (val.databaseType == "CLICKHOUSE") {
        this.roleShow = true;
      } else {
        this.roleShow = val.version.startsWith("8");
      }
      api.checkSuperAccount({ instanceId: this.instanceId }).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.$set(val, "loading", true);
            this.getAccountList();
          } else {
            this.bindXYData = {
              instanceId: this.instanceId,
              user: "",
              allowHost: "",
              uniqueKey: "",
              password: "",
              superAccount: true,
            };
            this.bindXYShow = true;
          }
        }
      });
    },
    allowClearSearchAccChange(e) {
      if (e.target.value) {
        return;
      }
      this.searchAccountList();
    },
    searchAccountList() {
      if (!this.username && !this.x && !this.y) {
        this.dataSource.splice(0);
        this.dataSource.push(...this.allDataSource);
        return;
      }
      if (this.username) {
        this.dataSource = this.allDataSource.filter((item) => {
          return item.user.indexOf(this.username) !== -1;
        });
      }
      if (this.x) {
        this.dataSource = this.allDataSource.filter((item) => {
          return item.x && item.x.indexOf(this.x) !== -1;
        });
      }
      if (this.y) {
        this.dataSource = this.allDataSource.filter((item) => {
          return item.y && item.y.indexOf(this.y) !== -1;
        });
      }
    },
    getAccountList() {
      let data = {
        instanceId: this.instanceId,
      };
      api
        .listAccount(data)
        .then((res) => {
          if (res.result == 200) {
            this.dataSource = res.data;
            this.dataSource.forEach((item) => {
              if (item.accountExtraInfo) {
                for (const key in item.accountExtraInfo) {
                  const value = item.accountExtraInfo[key];

                  this.$set(item, key, value);
                }
                this.$set(
                  item,
                  "xy",
                  item.accountExtraInfo.x + " / " + item.accountExtraInfo.y
                );
                this.$set(
                  item,
                  "superAccount",
                  item.accountExtraInfo.superAccount
                );
              } else {
                this.$set(item, "xy", "- / -");
                this.$set(item, "superAccount", false);
              }
            });
            this.allDataSource.splice(0);
            this.allDataSource.push(...this.dataSource);

            if (this.newAccountListShow) {
              this.searchAccountList();
            } else {
              this.dataSourceHostList.forEach((item) => {
                if (this.instanceId == item.id) {
                  this.$set(item, "loading", false);
                }
              });
              this.newAccountListShow = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRoleList() {
      let data = {
        instanceId: this.instanceId,
      };
      this.allRoleList.splice(0);
      this.roleList.splice(0);
      api.listRoles(data).then((res) => {
        if (res.result == 200) {
          res.data.roles.forEach((role) => {
            this.roleList.push({ role });
            this.allRoleList.push({ role });
          });
        }
      });
    },
    allowClearSearchRoleChange(e) {
      if (e.target.value) {
        return;
      }
      this.searchRoleList();
    },
    searchRoleList() {
      if (!this.searchRoleName) {
        this.roleList.splice(0);
        this.roleList.push(...this.allRoleList);
        return;
      }
      this.roleList = this.allRoleList.filter((item) => {
        return item.role && item.role.indexOf(this.searchRoleName) !== -1;
      });
    },
    getRoleListNew() {
      let data = {
        instanceId: this.instanceId,
      };
      this.allRoleList.splice(0);
      this.roleList.splice(0);
      api.listRolesNew(data).then((res) => {
        if (res.result == 200) {
          res.data.result.forEach((role) => {
            this.roleList.push({ role });
            this.allRoleList.push({ role });
          });
          this.searchRoleList();
        }
      });
    },
    addRole() {
      this.roleName = "";
      this.addRoleShow = true;
    },
    addRoleSubmit() {
      let data = {
        instanceId: this.instanceId,
        roleName: this.roleName,
      };
      api.addRole(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          if (this.newAccountListShow) {
            this.getRoleListNew();
          } else {
            this.getRoleList();
          }
          this.addRoleShow = false;
        }
      });
    },
    autoCompleteRole() {
      this.autoCompleteRoleDbList.splice(0);
      this.autoCompleteRoleShow = true;
    },
    autoCompleteRoleSubmit() {
      let data = {
        instanceId: this.instanceId,
        dbs: this.autoCompleteRoleDbList.join(","),
      };
      api.autoCompleteRole(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("自动补全成功");
          this.getRoleList();
          this.autoCompleteRoleShow = false;
        }
      });
    },
    autoCompleteRoleNew() {
      this.showDatabaseForSuper();
      this.autoCompleteRoleDbList.splice(0);
      this.autoCompleteRoleNewShow = true;
    },
    autoCompleteRoleNewSubmit() {
      let data = {
        instanceId: this.instanceId,
        databaseNameList: this.autoCompleteRoleDbList,
      };
      api.autoCompleteRoleNew(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("自动补全成功");
          this.getRoleListNew();
          this.autoCompleteRoleNewShow = false;
        }
      });
    },
    batchDeleteRole() {
      this.$axios
        .all(
          this.roleSelectedRowKeys.map((roleName) => {
            let data = {
              instanceId: this.instanceId,
              roleName,
            };
            return api.dropRole(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.roleSelectedRowKeys.splice(0);
          this.getRoleList();
        });
    },
    batchDeleteRoleNew() {
      this.$axios
        .all(
          this.roleSelectedRowKeys.map((roleName) => {
            let data = {
              instanceId: this.instanceId,
              roleName,
            };
            return api.dropRoleNew(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.roleSelectedRowKeys.splice(0);
          this.getRoleListNew();
        });
    },
    onRoleSelectChange(selectedRowKeys, selectedRows) {
      this.roleSelectedRowKeys.splice(0);
      selectedRows.forEach((item) => {
        this.roleSelectedRowKeys.push(item.role);
      });
    },
    deleteRole(val) {
      let data = {
        instanceId: this.instanceId,
        roleName: val.role,
      };
      api.dropRole(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.getRoleList();
        }
      });
    },
    editGrant(val) {
      this.databaseName = "";
      this.tableName = "";
      this.roleName = val.role;
      this.allGrantOptionList.splice(0);
      this.tableList.splice(0);
      this.tableList.push({ label: "任意表", value: "" });
      this.databaseList.push({ label: "任意库", value: "" });
      this.plainGrants = "";
      this.showDatabaseForSuper();
      this.showGrantOptions();
      this.listRolePriviledges();
    },
    showGrantOptions() {
      let data = {
        instanceId: this.instanceId,
        type: "DATABASE",
      };
      api.showGrantOptions(data).then((res) => {
        if (res.result == 200) {
          if (res.data.optionList) {
            res.data.optionList.forEach((key) => {
              this.allGrantOptionList.push({ key, label: key });
            });
          }
          this.editGrantShow = true;
        }
      });
    },
    listRolePriviledges() {
      let data = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
        tableName: this.tableName,
        roleName: this.roleName,
      };
      api.listRolePriviledges(data).then((res) => {
        if (res.result == 200) {
          if (res.data.optionList) {
            this.grantTargetKeys = res.data.optionList;
            this.grantUserList = res.data.optionList;
            const missingKeys = this.grantTargetKeys.filter(
              (item) => !this.allGrantOptionList.some((obj) => obj.key === item)
            );
            if (missingKeys.length > 0) {
              missingKeys.forEach((key) => {
                this.allGrantOptionList.push({ key, label: key });
              });
            }
            this.plainGrants = res.data.plainGrantList.join("\n");
          }
        }
      });
    },
    grantOptionChange(nextTargetKeys) {
      this.grantTargetKeys = nextTargetKeys;
    },
    grantOptionSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.grantSelectKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    editGrantSubmit() {
      let data = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
        tableName: this.tableName,
        roleName: this.roleName,
      };
      this.grantPriviledgesToRole(data);
      this.revokePriviledgesFromRole(data);
      this.editGrantShow = false;
    },
    grantPriviledgesToRole(data) {
      let priviledgeList = this.grantTargetKeys.filter(
        (item) => !this.grantUserList.includes(item)
      );
      if (priviledgeList.length == 0) {
        return;
      }
      api
        .grantPriviledgesToRole({
          ...data,
          priviledgeList,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("添加授权成功");
          }
        });
    },
    revokePriviledgesFromRole(data) {
      let priviledgeList = this.grantUserList.filter(
        (item) => !this.grantTargetKeys.includes(item)
      );
      if (priviledgeList.length == 0) {
        return;
      }
      api
        .revokePriviledgesFromRole({
          ...data,
          priviledgeList,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("取消授权成功");
          }
        });
    },
    showDatabaseForSuper() {
      let data = {
        instanceId: this.instanceId,
      };
      this.databaseList.splice(0);
      this.allDatabaseList.splice(0);
      api.showDatabaseForSuper(data).then((res) => {
        if (res.result == 200) {
          res.data.result.forEach((databaseName) => {
            this.databaseList.push({
              label: databaseName,
              value: databaseName,
            });
            this.allDatabaseList.push({
              label: databaseName,
              value: databaseName,
            });
          });
          if (this.accountTabsKey == "database") {
            this.searchDatabaseList();
          }
        }
      });
    },
    allowClearSearchDatabaseChange(e) {
      if (e.target.value) {
        return;
      }
      this.searchDatabaseList();
    },
    searchDatabaseList() {
      if (!this.searchDatabaseName) {
        this.databaseList.splice(0);
        this.databaseList.push(...this.allDatabaseList);
        return;
      }
      this.databaseList = this.allDatabaseList.filter((item) => {
        return item.label && item.label.indexOf(this.searchDatabaseName) !== -1;
      });
    },
    addDatabaseForSuper() {
      this.updateDatabaseForSuperTitle = "新增数据库";
      this.databaseName = "";
      this.updateDatabaseForSuperShow = true;
    },
    dropDatabaseForSuper(val) {
      this.updateDatabaseForSuperTitle = "删除数据库";
      this.dropDatabaseName = val.label;
      this.databaseName = "";
      this.updateDatabaseForSuperShow = true;
    },
    updateDatabaseForSuperSubmit() {
      let data = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
      };
      if (this.updateDatabaseForSuperTitle == "新增数据库") {
        api.addDatabase(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("新增成功");
            this.updateDatabaseForSuperShow = false;
            this.showDatabaseForSuper();
          }
        });
      } else {
        if (this.databaseName !== this.dropDatabaseName) {
          this.$message.warning("数据库名称不一致");
          return;
        }
        api.dropDatabase(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("删除成功");
            this.updateDatabaseForSuperShow = false;
            this.showDatabaseForSuper();
          }
        });
      }
    },
    showTablesForSuper() {
      this.tableList.splice(0);
      this.tableList.push({ label: "任意表", value: "" });
      if (!this.databaseName) {
        this.listRolePriviledges();
        return;
      }
      let data = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
      };
      api.showTablesForSuper(data).then((res) => {
        if (res.result == 200) {
          res.data.result.forEach((tableName) => {
            this.tableList.push({ label: tableName, value: tableName });
          });
          this.tableName = "";
          this.listRolePriviledges();
        }
      });
    },
    deleteRoleNew(val) {
      let data = {
        instanceId: this.instanceId,
        roleName: val.role,
      };
      api.dropRoleNew(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.roleList = this.roleList.filter((item) => {
            return item.role !== val.role;
          });
        }
      });
    },
    allowClearXyChange(e) {
      if (e.target.value) {
        return;
      }
      this.getDbList(1);
    },
    batchDeleteAccount() {
      this.$axios
        .all(
          this.accountSelectedRowKeys.map((id) => {
            return api.dbDelete({ id });
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.accountSelectedRowKeys.splice(0);
          this.getDbList(1);
        })
        .catch(() => {
          this.accountSelectedRowKeys.splice(0);
          this.getDbList(1);
        });
    },
    onAccountSelectChange(selectedRowKeys, selectedRows) {
      this.accountSelectedRowKeys = selectedRowKeys;
      this.accountSelectedRows = selectedRows;
    },
    // 关闭账号列表
    closeAccountList() {
      this.dataSource = [];
      this.accountTabsKey = "ordinary";
      this.systemAccount = false;
      this.innerHost = "";
      this.roleSelectedRowKeys.splice(0);
      this.accountSelectedRowKeys.splice(0);
    },
    // 复制数据库信息
    copyInfo(val) {
      let data = {
        id: val.id,
      };
      let parameter = {
        host: "",
        port: val.port,
        user: val.username,
        pwd: "",
      };
      let dbName = val.dbName;
      let message = ""; // copy内容
      api
        .getDbPassword(data)
        .then((res) => {
          if (res.result === 200) {
            if (val.innerAccess) {
              if (res.data.privateAddress) {
                parameter.host = res.data.privateAddress;
              } else {
                parameter.host = val.innerHost;
              }
            } else {
              if (res.data.publicAddress) {
                parameter.host = res.data.publicAddress;
              } else {
                parameter.host = val.host;
              }
            }
            parameter.password = res.data.password;
            message = this.$common.copyInfo(parameter, dbName); // 复制数据库信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {
          message = this.$common.copyInfo(parameter, dbName);
          this.$copyText(message).then(
            (e) => {
              // 复制成功
              this.$message.success("复制成功");
            },
            (e) => {
              // 复制失败
              this.$message.error("复制失败");
            }
          );
        });
    },
    // 复制数据库坐标信息
    copyCoordinate(val) {
      let data = {
        id: val.id,
      };
      let parameter = {
        host: "",
        port: val.port,
        userName: val.username,
        password: "",
        x: val.x,
        y: val.y,
      };
      let dbName = val.dbName;
      let message = ""; // copy内容
      api
        .getDbPassword(data)
        .then((res) => {
          if (res.result === 200) {
            if (val.innerAccess) {
              if (res.data.privateAddress) {
                parameter.host = res.data.privateAddress;
              } else {
                parameter.host = val.innerHost;
              }
            } else {
              if (res.data.publicAddress) {
                parameter.host = res.data.publicAddress;
              } else {
                parameter.host = val.host;
              }
            }
            parameter.password = res.data.password;
            message = this.$common.copyCoordinate(parameter, dbName); // 复制数据库信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {});
    },
    // 点击删除
    deleteData(val) {
      let contentData = {
        数据库: val.dbName,
        登录名: val.username,
        host: val.allowHost,
      };
      let contentText = "";
      for (let i in contentData) {
        if (contentData[i]) {
          contentText += i + ": " + contentData[i] + ",";
        }
      }
      let contentList = contentText.split(",");
      this.$confirm({
        title: "删除",
        content: (h) => (
          <div>
            {contentList[0]}
            <br />
            {contentList[1]}
            <br />
            {contentList[2]}
            <br />
            是否删除该账号？
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          api.dbDelete({ id: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getDbList(1);
            }
          });
        },
        onCancel() {},
      });
    },
    dropUser(val) {
      this.$confirm({
        title: "删除",
        content: (h) => (
          <div>
            是否删除{" "}
            <b>
              {val.user}@{val.allowHost}
            </b>{" "}
            该账号？
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            instanceId: this.instanceId,
            user: val.user,
            allowHost: val.allowHost,
            accountExtraInfoId: val.id,
          };
          api.dropUser(data).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getAccountList();
            }
          });
        },
        onCancel() {},
      });
    },
    // 是否内部库
    innerDbChange(e) {
      this.actionForm.innerDb = e.target.value;
    },
    //随机root密码-新增实例
    rootPasswordAdd() {
      let psw = this.$common.randomStr(false, 12);
      this.addDatabaseForm.rootPassword = psw;
    },
    //随机密码-新增账号
    randomPswAdd() {
      let psw = this.$common.randomStr(false, 12);
      this.actionForm.password = psw;
    },
    //密码显示/隐藏
    changeInpuntTpye(type) {
      this.passwordType = type;
    },
    // 点击检查
    inspect(val) {
      let data = {
        id: val.id,
      };
      api.dbCheck(data).then((res) => {
        if (res.result == 200) {
          this.$message.success(res.data);
        }
      });
    },
    inspectNew(val) {
      let data = {
        accountExtraInfoId: val.id,
      };
      api.checkAuth(data).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.$message.success("检查成功");
          } else {
            this.$message.success("检查失败");
          }
        }
      });
    },
    bindingRole(val) {
      let data = {
        instanceId: this.instanceId,
      };
      this.bindingRoleList.splice(0);
      this.bindingUserRoleKeys.splice(0);
      this.bindingSelectRoleKeys.splice(0);
      this.bindingTargetRoleKeys.splice(0);
      api.listRoles(data).then((res) => {
        if (res.result == 200) {
          this.bindingRoleShow = true;
          this.$nextTick(() => {
            res.data.roles.forEach((role) => {
              this.bindingRoleList.push({ role, key: role });
            });
            this.bindingRoleUsernameLabel = val.usernameLabel;
            this.bindingRoleUser = val.username;
            this.bindingRoleAllowHost = val.allowHost;
            data.user = val.username;
            data.allowHost = val.allowHost;
            api.listUserRoles(data).then((res) => {
              if (res.result == 200) {
                this.bindingUserRoleKeys.push(...res.data.roles);
                this.bindingTargetRoleKeys.push(...res.data.roles);
              }
            });
          });
        }
      });
    },
    bindingRoleNew(val) {
      let data = {
        instanceId: this.instanceId,
      };
      this.bindingRoleList.splice(0);
      this.bindingUserRoleKeys.splice(0);
      this.bindingSelectRoleKeys.splice(0);
      this.bindingTargetRoleKeys.splice(0);
      api.listRolesNew(data).then((res) => {
        if (res.result == 200) {
          this.bindingRoleShow = true;
          this.$nextTick(() => {
            res.data.result.forEach((role) => {
              this.bindingRoleList.push({ role, key: role });
            });
            this.bindingRoleUsernameLabel = val.user + "@" + val.allowHost;
            this.bindingRoleUser = val.user;
            this.bindingRoleAllowHost = val.allowHost;
            data.user = val.user;
            data.allowHost = val.allowHost;
            api.listUserRolesNew(data).then((res) => {
              if (res.result == 200) {
                this.bindingUserRoleKeys.push(...res.data.result);
                this.bindingTargetRoleKeys.push(...res.data.result);
              }
            });
          });
        }
      });
    },
    handleChange(nextTargetKeys) {
      this.bindingTargetRoleKeys = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.bindingSelectRoleKeys = [
        ...sourceSelectedKeys,
        ...targetSelectedKeys,
      ];
    },
    bindingRoleSubmit() {
      let data = {
        instanceId: this.instanceId,
        user: this.bindingRoleUser,
        allowHost: this.bindingRoleAllowHost,
      };
      this.grantRoleToUser(data);
      this.revokeRoleFromUser(data);
      this.bindingRoleShow = false;
    },
    grantRoleToUser(data) {
      let roleList = this.bindingTargetRoleKeys.filter(
        (item) => !this.bindingUserRoleKeys.includes(item)
      );
      if (roleList.length == 0) {
        return;
      }
      if (this.newAccountListShow) {
        api.grantRoleToUserNew({ ...data, roleList }).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加授权成功");
          }
        });
      } else {
        api
          .grantRoleToUser({ ...data, roles: roleList.join(",") })
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("添加授权成功");
            }
          });
      }
    },
    revokeRoleFromUser(data) {
      let roleList = this.bindingUserRoleKeys.filter(
        (item) => !this.bindingTargetRoleKeys.includes(item)
      );
      if (roleList.length == 0) {
        return;
      }
      if (this.newAccountListShow) {
        api.revokeRoleFromUserNew({ ...data, roleList }).then((res) => {
          if (res.result == 200) {
            this.$message.success("取消授权成功");
          }
        });
      } else {
        api
          .revokeRoleFromUser({ ...data, roles: roleList.join(",") })
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("取消授权成功");
            }
          });
      }
    },
    bindingUser(val) {
      this.expireTimeShow = false;
      this.bindingUserData = {
        userLoginName: undefined,
        instanceId: this.instanceId,
        accountExtraInfoId: val.id,
      };
      this.bindUserShow = true;
      this.getInstanceList();
      this.getUserNameList();
    },
    getUserNameList(username) {
      let data = {
        username,
      };
      api.sqlUerList(data).then((res) => {
        if (res.result === 200) {
          this.userNameList = res.data;
        }
      });
    },
    getInstanceList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      api.instanceList(data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.instanceList = res.data.records;
          }
        }
      });
    },
    expireTimeShowChange() {
      if (this.expireTimeShow) {
        this.expireTime = 1;
        this.expireUnit = "天";
      }
    },
    bindingUserSubmit() {
      let data = { ...this.bindingUserData };
      delete data.instanceId;
      if (this.expireTimeShow) {
        const unitsInSeconds = {
          月: 30 * 24 * 60 * 60, // 假设每月按30天算
          天: 24 * 60 * 60,
          小时: 60 * 60,
          分钟: 60,
          秒: 1,
        };

        data.expireSeconds =
          this.expireTime * 1 * unitsInSeconds[this.expireUnit];
      } else {
        data.expireSeconds = -1;
      }

      this.loading = true;
      grant2User(data).then((res) => {
        if (res.result === 200) {
          this.bindUserShow = false;
          this.loading = false;
          this.$message.success("绑定成功");
        }
      });
    },
    // 点击新增账号
    addAccount() {
      this.$set(this.actionForm, "dbName", "mysql8_default");
      this.randomPswAdd();
      this.visibleAdd = true;
    },
    // 选择本机host
    localHostChange(e) {
      this.localHost = e.target.checked;
      if (this.localHost) {
        this.actionForm.allowHost = this.innerHost;
        this.actionForm.innerAccess = true;
      }
    },
    // 确定新增
    addOk() {
      let data = {
        instanceId: this.instanceId,
        username: this.actionForm.username,
        password: this.actionForm.password,
        allowHost: this.actionForm.allowHost,
        privilege: this.actionForm.privilege,
        innerAccess: this.actionForm.innerAccess,
        systemAccount: this.actionForm.systemAccount,
      };
      if (!this.actionForm.systemAccount || !this.roleShow) {
        data.dbName = this.actionForm.dbName;
        if (!data.dbName) {
          this.$message.warning("数据库名不能为空");
          return;
        }
      }
      if (!data.username) {
        this.$message.warning("账号不能为空");
        return;
      }
      if (!data.password) {
        this.$message.warning("密码不能为空");
        return;
      }
      if (!data.allowHost) {
        this.$message.warning("host不能为空");
        return;
      }
      this.loading = true;
      api
        .hostAddDatabase(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.query();
            if (this.newAccountListShow) {
              this.getAccountList();
            } else {
              this.getDbList(1);
            }
            this.visibleAdd = false;
            this.$message.success("新增成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭新增
    closeAdd() {
      this.actionForm = {
        host: "",
        dbName: undefined,
        port: "3306",
        username: "",
        password: "",
        innerDb: false,
        allowHost: "%",
        privilege: "all",
        innerAccess: false,
        systemAccount: false,
      };
      this.databaseList = [];
      this.localHost = false;
    },
    addAccountNew() {
      this.actionForm = {
        user: "",
        password: "",
        allowHost: "%",
        superAccount: false,
      };
      this.randomPswAdd();
      this.visibleAddNew = true;
    },
    // 确定新增
    addOkNew() {
      let data = {
        instanceId: this.instanceId,
        ...this.actionForm,
      };
      if (!data.user) {
        this.$message.warning("账号不能为空");
        return;
      }
      if (!data.password) {
        this.$message.warning("密码不能为空");
        return;
      }
      if (!data.allowHost) {
        this.$message.warning("host不能为空");
        return;
      }
      this.loading = true;
      api
        .addUser(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.getAccountList();
            this.visibleAddNew = false;
            this.$message.success("新增成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    //点击查询
    query(index) {
      if (index) {
        this.pageNoHost = index;
      }
      let data = {
        pageNo: this.pageNoHost,
        pageSize: 10,
        host: this.host.trim(),
        name: this.dataBaseName.trim(),
        reachable: this.reachable,
      };
      this.getHostList(data);
    },
    //查询数据库列表
    getHostList(data) {
      this.loadingTable = true;
      api
        .instanceList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            let list = res.data.records;
            let actionDate = new Date().getTime();
            list.forEach((item, i) => {
              item.key = i;
              item.index = i + 1;
              if (actionDate - item.lastSyncDate > 86400000) {
                item.color = true;
              } else {
                item.color = false;
              }
              item.lastSyncDateLabel = this.$common.transformTime(
                item.lastSyncDate
              );
              if (item.needBackup) {
                item.backupLabel = "是";
              } else {
                item.backupLabel = "否";
              }
              if (item.innerDb) {
                item.innerDbLabel = "是";
              } else {
                item.innerDbLabel = "否";
              }
              item.loading = false;
            });
            this.dataSourceHostList = list;
            this.paginationHost = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItemHost(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    //分页 -- 数据列表
    changePageItemHost(current) {
      this.query(current);
    },
    //查询db表数据
    getDbList(index, size) {
      if (index) {
        this.pageNo = index;
      }
      if (size) {
        this.pageSize = size;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        x: this.x,
        y: this.y,
        username: this.username,
        instanceId: this.instanceId,
        systemAccount: this.systemAccount,
      };
      api.dbList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i;
            item.index = i + 1;
            if (item.needBackup) {
              item.backupLabel = "是";
            } else {
              item.backupLabel = "否";
            }
            if (item.innerDb) {
              item.innerDbLabel = "是";
            } else {
              item.innerDbLabel = "否";
            }
            item.usernameLabel = item.username + "@" + item.allowHost;
            item.xy = item.x + " / " + item.y;
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current, size) => this.changePageItem(current, size),
            onShowSizeChange: (current, size) =>
              this.changePageItem(current, size),
          };
        }
      });
    },
    //分页
    changePageItem(current, size) {
      this.getDbList(current, size);
    },
    // 点击查看密码
    checkPassword(val) {
      let data = {
        id: val.id,
      };
      api.getDbPassword(data).then((res) => {
        if (res.result == 200) {
          this.password = "";
          if (res.data) {
            this.password = res.data.password;
          }
          this.$copyText(this.password).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    checkPasswordNew(val, index) {
      let data = {
        id: val.id,
      };
      this.password = "";
      let text = "";
      api.getDbPasswordNew(data).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.password = res.data.password;
          }
          if (index == 1) {
            text = this.password;
          } else if (index == 2) {
            let parameter = {
              host: res.data.privateAddress,
              port: res.data.port,
              user: val.user,
              pwd: this.password,
            };
            text = this.$common.copyInfo(parameter, ""); // 复制密码信息
          } else {
            let parameter = {
              host: res.data.privateAddress,
              port: res.data.port,
              username: val.user,
              password: this.password,
              x: res.data.x,
              y: res.data.y,
            };

            text = this.$common.copyCoordinate(parameter, ""); // 复制坐标信息
          }

          this.$copyText(text).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    bindXY(val) {
      this.bindXYData = {
        instanceId: this.instanceId,
        user: val.user,
        allowHost: val.allowHost,
        uniqueKey: val.user,
        password: "",
        superAccount: false,
      };
      this.bindXYShow = true;
    },
    bindXYSubmit() {
      let data = { ...this.bindXYData };
      if (!this.bindXYData.uniqueKey || !this.bindXYData.password) {
        this.$message.warning("请填写必填项");
        return;
      }
      this.loading = true;
      api.insertExtraInfoForAccount(data).then((res) => {
        if (res.result == 200) {
          this.getAccountList();
          this.loading = false;
          this.bindXYShow = false;
          this.$message.success("绑定成功");
        }
      });
    },
    // 点击修改密码
    changePassword(val) {
      let psw = this.$common.randomStr(false, 12);
      this.newPassword = psw;
      let data = {
        id: val.id,
        newPassword: this.newPassword,
      };
      api.resetPassword(data).then((res) => {
        if (res.result == 200) {
          this.getDbList(1);
          this.$message.success("修改成功");
        }
      });
    },
    changePasswordNew(val) {
      let psw = this.$common.randomStr(false, 12);
      this.newPassword = psw;
      let data = {
        accountExtraInfoId: val.id,
        password: this.newPassword,
      };
      api.resetPasswordNew(data).then((res) => {
        if (res.result == 200) {
          if (res.data.success) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.errorMessage);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.management {
  position: relative;
}
.table-mask {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  .table-mask-time {
    position: absolute;
    top: 255px;
    left: 45%;
    width: 300px;
    height: 80px;
    border-radius: 10px;
    background: rgba(20, 20, 20, 0.8);
    font-size: 28px;
    line-height: 80px;
    color: #fff;
    text-align: center;
    .time-lable {
      display: inline-block;
      width: 48px;
    }
  }
}
.add_Customer {
  position: absolute;
  right: -75px;
  top: -10px;
}
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
.randomLocalHost {
  position: absolute;
  right: -76px;
  top: -10px;
}
.isRed {
  color: #ff0000;
}
.copy-class {
  position: absolute;
  top: -9px;
  right: 13px;
  font-size: 18px;
  z-index: 3;
}
.copy-class2 {
  position: absolute;
  top: -9px;
  right: 100px;
  font-size: 18px;
  z-index: 3;
}
.expireTimeSwitch {
  background-color: #ff8000 !important;
}
:deep() .ant-switch-checked {
  background-color: #1890ff !important;
}
</style>
<style lang="scss">
.delete-btn {
  .delete-a {
    color: #f5222d;
  }
  .disabled {
    color: rgba(0, 0, 0, 0.25);
  }
}
.performance-title {
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.account-modal {
  .ant-modal-body {
    padding: 10px;
  }
}
</style>
