<template>
  <div class="backupTab">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="定时备份">
        <router-view executeType="SCHEDULED" name="four" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="立即备份">
        <router-view executeType="NO_DELAY" name="four" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "backupTab",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tableManagement/backup/backupTimed") {
          this.active = "1";
        } else if (newVal == "/tableManagement/backup/backupImmediately") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tableManagement/backup/backupTimed") return;
          this.$router.replace("/tableManagement/backup/backupTimed");
        } else if (newVal === "2") {
          if (this.$route.path == "/tableManagement/backup/backupImmediately")
            return;
          this.$router.replace("/tableManagement/backup/backupImmediately");
        }
      },
    },
  },
};
</script>
