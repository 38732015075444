var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","width":"100vw"},on:{"click":function($event){_vm.isActive = false}}},[_c('a-layout',{attrs:{"id":"components-layout-demo-custom-trigger"}},[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0 30px 0 0","position":"fixed","width":"100%","height":"50px","box-shadow":"0 4px 16px 0 rgb(0 0 0 / 15%)","z-index":"999"}},[_c('div',{staticClass:"header-cc"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 切换到sql命令页面 ")]),_c('a-button',{staticClass:"button",staticStyle:{"margin-right":"5px"},attrs:{"type":"link"},on:{"click":_vm.jumpSQL}},[_c('icon-font',{attrs:{"type":"icon-qiehuan"}})],1)],2),_c('div',{staticClass:"title-theme"},[_vm._v("数据库管理")]),_c('a-dropdown',{staticClass:"user-name-cc",staticStyle:{"float":"right"}},[_c('span',{staticStyle:{"cursor":"pointer","padding-left":"10px","font-size":"16px"}},[(_vm.loginAvatarUrl)?_c('a-avatar',{staticClass:"avatar",attrs:{"size":"small","shape":"circle","src":_vm.loginAvatarUrl}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.loginNickName))])],1),_c('a-menu',{staticStyle:{"width":"150px"},attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',[_c('a',{staticStyle:{"display":"inline-block","text-align":"center","width":"100%","padding":"0","margin":"0"},attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logOut()}}},[_c('a-icon',{attrs:{"type":"poweroff"}}),_c('span',[_vm._v("退出登录")])],1)])],1)],1)],1)]),_c('a-layout',[_c('a-layout-sider',{style:({
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          top: '50px',
          left: 0,
        }),attrs:{"trigger":null,"collapsible":"","width":"180","theme":"light"},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('a-menu',{attrs:{"mode":"inline","selectedKeys":[_vm.selectedKeys],"openKeys":_vm.openKeys},on:{"click":_vm.selectClick,"openChange":_vm.onOpenChange}},_vm._l((_vm.list),function(menu){return _c('a-menu-item',{key:menu.url},[_c('icon-font',{attrs:{"type":menu.iconType}}),_c('span',[_vm._v(_vm._s(menu.name))])],1)}),1),_c('div',{staticClass:"setting"},[_c('a-icon',{staticClass:"switch",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":() => (_vm.collapsed = !_vm.collapsed)}})],1)],1),_c('a-layout',{class:{
          isContent: true,
          onContentPc: _vm.isPcShow,
        },style:({ padding: '10px 16px' }),attrs:{"id":"HomeContent"}},[_c('div',{staticStyle:{"background":"rgb(255, 255, 255)","padding":"24px"}},[_c('keep-alive',{attrs:{"include":"monitor"}},[_c('router-view',{attrs:{"name":"second"}})],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }