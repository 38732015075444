<template>
  <div class="userSqlPermission">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="searchCondition.userLoginName"
          placeholder="搜索用户"
          showSearch
          :filterOption="false"
          @search="getUserNameList"
          @change="query(1)"
          allowClear
        >
          <a-select-option
            v-for="item in userNameList"
            :key="item.userLoginName"
            >{{ item.userName }}({{ item.userLoginName }})</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        权限：
        <a-select
          v-model="searchCondition.permission"
          placeholder="可搜索"
          showSearch
          :filterOption="false"
          @change="query(1)"
          allowClear
        >
          <a-select-option
            v-for="item in permissionList"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">添加授权</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="user" slot-scope="text, record">
        {{ text }}@{{ record.allowHost }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认撤销授权{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">撤销授权</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="添加授权"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="用户:">
          <a-select
            v-model="updateData.userLoginName"
            placeholder="搜索用户"
            showSearch
            :filterOption="false"
            @search="getUserNameList"
            @change="getUserPermissionList"
          >
            <a-select-option
              v-for="item in userNameList"
              :key="item.userLoginName"
              >{{ item.userName }}({{ item.userLoginName }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="权限:">
          <a-select
            v-model="updateData.permissionList"
            placeholder="可搜索"
            showSearch
            :filterOption="false"
            mode="multiple"
            :disabled="!updateData.userLoginName"
          >
            <a-select-option
              v-for="item in permissionList"
              :key="item"
              :value="item"
              :disabled="existingPermissionList.includes(item)"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/userSqlPermission.js";
import { sqlUerList } from "../lib/databaseList.js";

export default {
  name: "userSqlPermission",
  data() {
    return {
      searchCondition: {
        userLoginName: undefined,
      },
      permissionList: [],
      userNameList: [],
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "用户",
          dataIndex: "userLoginName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "权限",
          dataIndex: "permission",
          ellipsis: true,
          width: 200,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      existingPermissionList: [],
    };
  },
  mounted() {
    this.getPermissionList();
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ...this.searchCondition,
      };
      this.loadingTable = true;
      api
        .listPermissionPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    getUserPermissionList() {
      if (!this.updateData.userLoginName) {
        return;
      }
      let data = {
        userLoginName: this.updateData.userLoginName,
      };
      api.listUserPermission(data).then((res) => {
        if (res.result === 200) {
          this.existingPermissionList = res.data;
        }
      });
    },
    // 查询实例
    getPermissionList() {
      api.listPermission().then((res) => {
        if (res.result === 200) {
          this.permissionList = res.data;
        }
      });
    },
    // 查询用户列表
    getUserNameList(username) {
      let data = {
        username,
      };
      sqlUerList(data).then((res) => {
        if (res.result === 200) {
          this.userNameList = res.data;
        }
      });
    },
    // 新增
    add() {
      this.addKeyVisible = true;
      this.updateData = {
        userLoginName: undefined,
        permissionList: [],
      };
    },
    add_submit() {
      let data = { ...this.updateData };

      this.loading = true;
      api.grantPermissionToUser(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.loading = false;
          this.$message.success("添加授权成功");
          this.query(1);
        }
      });
    },
    deleteClick(val) {
      let data = {
        userLoginName: val.userLoginName,
        permission: val.permission,
      };
      api.revokePermissionFromUser(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("撤销授权成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.expireTimeSwitch {
  background-color: #ff8000 !important;
}
:deep() .ant-switch-checked {
  background-color: #1890ff !important;
}
</style>
