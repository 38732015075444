<template>
  <div class="backup">
    <div class="searchCondition">
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="status" slot-scope="text, record" style="width: 100%">
        <a-tag :color="record.tagColor">
          {{ record.statusLabel }}
          <a-icon type="loading" v-if="record.status === 'RUNNING'" />
        </a-tag>
      </span>
      <span slot="name" slot-scope="text, record" style="width: 100%">
        <a-tooltip :overlayStyle="{ maxWidth: 'none' }">
          <div slot="title">
            数据库信息:<br />
            host: {{ record.databaseInstance.host }}<br />
            name: {{ record.databaseInstance.name }}<br />
          </div>
          {{ record.name }}
        </a-tooltip>
      </span>
      <span slot="instanceName" slot-scope="text, record" style="width: 100%">
        {{ record.databaseInstance.name }}
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          checkedChildren="开启"
          unCheckedChildren="关闭"
          :checked="record.enabled"
          @change="modifyFullSyncCronTask(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="subtaskList(record, 1)">子任务列表</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="backup(record)"
          v-if="executeType == 'SCHEDULED'"
        >
          <template slot="title">确认是否立即执行</template>
          <a href="javascript:;">立即执行</a>
        </a-popconfirm>
        <a v-else href="javascript:;" @click="copy(record)">复制</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteTemplate(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
        <template v-if="executeType == 'SCHEDULED'">
          <a-divider type="vertical" />
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" href="#">
              其他操作 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="selectedTable(record)">选择表</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="policyBackup(record)"
                  >备份策略</a
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="800px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="选择实例:" required>
          <a-select
            v-model="addData.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            option-filter-prop="children"
            showSearch
            @change="handleInstanceListChange"
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="addData.databaseNames"
            placeholder="请选择数据库"
            allowClear
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :mode="executeType == 'SCHEDULED' ? 'multiple' : 'default'"
            :disabled="!addData.instanceId"
            @change="showTablesForSuper"
          >
            <a-select-option
              v-for="item in databaseList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="任务名称:">
          <a-input
            v-model.trim="addData.name"
            placeholder="请输入任务名称"
            allowClear
          />
        </a-form-item>
        <a-form-item>
          <div style="margin-left: 160px">
            <div>
              <a-checkbox v-model="addData.extraInfo.drop">
                Drop table if exists
              </a-checkbox>
            </div>
            <div>
              <a-checkbox v-model="addData.extraInfo.structure">
                Include table structure
              </a-checkbox>
            </div>
            <div>
              <a-checkbox v-model="addData.extraInfo.content">
                Include table content
              </a-checkbox>
            </div>
          </div>
        </a-form-item>
        <template v-if="executeType == 'NO_DELAY'">
          <a-form-item label="类型">
            <a-radio-group v-model="filterMode" style="margin-bottom: 10px">
              <a-radio value="INCLUDE">包含</a-radio>
              <a-radio value="EXCLUDE">排除</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-transfer
            :data-source="tableList"
            :target-keys="selectedTableList"
            :selected-keys="tableSelectKeys"
            :render="(item) => item.table"
            :listStyle="{ width: '350px', height: '300px' }"
            showSearch
            @change="handleChange"
            @selectChange="handleSelectChange"
          />
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="子任务列表"
      v-model="subtaskListShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(subtaskTableColumns)"
        :columns="subtaskTableColumns"
        :dataSource="subtaskTableDataSource"
        :pagination="subtaskTablePagination"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag :color="record.tagColor">
            {{ record.statusLabel }}
            <a-icon type="loading" v-if="record.status === 'RUNNING'" />
          </a-tag>
        </span>
        <span slot="archiveTitle">
          归档状态
          <a-tooltip placement="left">
            <div slot="title">归档是指将文件从nas传到oss上</div>
            <a-icon
              type="question-circle"
              style="color: #1879ff; font-size: 14px"
            />
          </a-tooltip>
        </span>
        <span slot="archive" slot-scope="text, record" style="width: 100%">
          <a-tag :color="text ? 'green' : 'red'">
            {{ text ? "已归档" : "未归档" }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="detailLog(record, 1, 10)">详细记录</a>
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="recover(record)"
            :disabled="record.status == 'RUNNING'"
            >恢复</a
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteBackupOperation(record)"
          >
            <template slot="title">确认是否删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="subtaskListShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="详细记录"
      v-model="detailShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          表名：
          <a-input
            placeholder="请输入表名"
            v-model.trim="detailConditionTableName"
            @pressEnter="detailLog(null, 1)"
            @change="allowClearDetailLogChange"
            allowClear
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="detailLog(null, 1)" icon="search"
            >查询</a-button
          >
          <a-button
            type="primary"
            @click="batchDownloadTable"
            :disabled="selectedRowKeys.length == 0"
          >
            批量下载
          </a-button>
          <a-button
            type="primary"
            @click="recover()"
            :disabled="selectedRowKeys.length == 0"
          >
            批量恢复
          </a-button>
          <a-tooltip placement="left">
            <div slot="title">将oss文件复制到另一个文件夹下面</div>
            <a-button
              type="primary"
              @click="archive()"
              :disabled="selectedRowKeys.length == 0"
            >
              归档备份
            </a-button>
          </a-tooltip>
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(detailTableColumns)"
        :columns="detailTableColumns"
        :dataSource="detailTableDataSource"
        :pagination="detailTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag :color="record.tagColor">
            {{ record.statusLabel }}
            <a-icon type="loading" v-if="record.status === 'RUNNING'" />
          </a-tag>
          <a-tooltip
            placement="left"
            v-if="record.recoverId !== null && record.recoverId !== -1"
          >
            <div slot="title">点击查看</div>
            <a-button
              type="link"
              size="small"
              icon="eye"
              @click="viewDataImportLog(record)"
            />
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            @click="downloadTable(record)"
            :disabled="
              record.status !== 'SUCCESS' && record.status !== 'ARCHIVE'
            "
            >下载</a
          >
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="recover(null, record)"
            :disabled="
              (record.status !== 'SUCCESS' && record.status !== 'ARCHIVE') ||
              record.recoverId !== null
            "
            >恢复</a
          >
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="排除表"
      v-model="selectedTableShow"
      :maskClosable="false"
      width="800px"
    >
      <a-radio-group v-model="filterMode" style="margin-bottom: 10px">
        <a-radio value="INCLUDE">包含</a-radio>
        <a-radio value="EXCLUDE">排除</a-radio>
      </a-radio-group>
      <a-transfer
        :data-source="tableList"
        :target-keys="selectedTableList"
        :selected-keys="tableSelectKeys"
        :render="(item) => item.table"
        :listStyle="{ width: '350px', height: '300px' }"
        showSearch
        @change="handleChange"
        @selectChange="handleSelectChange"
      />
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="selectedTableShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="selectedTableSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <policy-backup ref="policyBackup" :data="data" @submit="query" />

    <a-modal
      title="恢复"
      v-model="recoverShow"
      v-if="recoverShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item>
          <span slot="label">
            选择实例
            <a-tooltip>
              <template slot="title">
                如需恢复到其他数据库可以选择实例和数据库,默认为空<br />
                只能选择同种类型的数据库进行恢复
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="databaseInfo.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            showSearch
            @change="handleInstanceListChange"
          >
            <a-select-option
              v-for="item in instanceList"
              :key="item.id"
              :disabled="databaseType !== item.databaseType"
              >{{ item.name }}({{ item.host }}:{{ item.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="databaseInfo.databaseName"
            placeholder="请选择数据库"
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :disabled="!databaseInfo.instanceId"
          >
            <a-select-option
              v-for="item in databaseList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="recoverShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="recoverSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="归档"
      v-model="archiveShow"
      v-if="archiveShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="bucketName:">
          <a-select
            v-model="bucketName"
            placeholder="可搜索"
            showSearch
            @change="getArchiveDir"
          >
            <a-select-option
              v-for="item in bucketNameList"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="归档目录:">
          <a-select
            v-model="archiveDir"
            :disabled="!bucketName"
            placeholder="可搜索"
            showSearch
          >
            <a-select-option
              v-for="item in archiveDirList"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="archiveShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="archiveSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="导入记录"
      v-model="viewDataImportLogShow"
      v-if="viewDataImportLogShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="实例:">
          {{ dataImportLog.instanceName }}
        </a-form-item>
        <a-form-item label="数据库:">
          {{ dataImportLog.databaseName }}
        </a-form-item>
        <a-form-item label="数据条数:">
          {{ dataImportLog.totalCount }}
        </a-form-item>
        <a-form-item label="耗时:">
          {{
            calculateTimeDifference(
              dataImportLog.startDate,
              dataImportLog.endDate
            )
          }}
        </a-form-item>
        <a-form-item label="状态:">
          <a-tag :color="dataImportLog.tagColor">
            {{ dataImportLog.statusLabel }}
          </a-tag>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="viewDataImportLogShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/backup.js";
import {
  showDatabaseForSuper,
  showTablesForSuper,
} from "../lib/databaseList.js";
import { dataImportLogList, listUserInstance } from "../lib/sql.js";
import policyBackup from "./policyBackup.vue";
import moment from "moment";

export default {
  name: "backup",
  props: ["executeType"],
  components: {
    policyBackup,
  },
  data() {
    return {
      databaseName: undefined,
      instanceList: [],
      databaseList: [],
      addData: {
        instanceId: undefined,
        databaseNames: [],
        extraInfo: {
          drop: false,
          structure: true,
          content: true,
        },
        name: "",
      },
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      columns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "实例",
          ellipsis: true,
          dataIndex: "instanceName",
          scopedSlots: { customRender: "instanceName" },
        },
        {
          title: "数据库",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "上次执行时间",
          ellipsis: true,
          dataIndex: "lastBackupDate",
          width: 150,
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextExecuteTime",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 270,
        },
      ],
      tableColumns: [],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      parentId: undefined,
      taskId: undefined,
      subtaskPageNo: 1,
      subtaskTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          ellipsis: true,
          dataIndex: "archive",
          scopedSlots: { title: "archiveTitle", customRender: "archive" },
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "timeFormat",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      subtaskTableDataSource: [],
      subtaskTablePagination: {},
      subtaskListShow: false,
      data: {},
      detailConditionTableName: "",
      detailPageNo: 1,
      detailPageSize: 10,
      detailTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "数据库名",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "表名",
          ellipsis: true,
          dataIndex: "tableName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 120,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "timeFormat",
          width: 100,
        },
        {
          title: "文件大小",
          ellipsis: true,
          sorter: (a, b) => a.fileSize - b.fileSize,
          dataIndex: "length",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          sorter: (a, b) => a.gmtCreatedMilliSeconds - b.gmtCreatedMilliSeconds,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      detailTableDataSource: [],
      detailTablePagination: {},
      detailShow: false,
      selectedRowKeys: [],
      selectedRows: [],
      selectedTableShow: false,
      selectedTableList: [],
      tableList: [],
      tableSelectKeys: [],
      backUpId: "",
      filterMode: "",
      databaseInfo: {
        instanceId: undefined,
        databaseName: undefined,
        excludeDatabase: true,
      },
      ids: [],
      recoverShow: false,
      instanceId: undefined,
      databaseName: undefined,
      archiveDir: undefined,
      bucketName: undefined,
      archiveDirList: [],
      bucketNameList: [],
      archiveShow: false,
      dataImportLog: {},
      viewDataImportLogShow: false,
      recoverId: "",
      refreshDataImportLogTimer: null,
      databaseType: "",
    };
  },
  watch: {
    executeType: {
      immediate: true,
      handler(newVal) {
        this.tableColumns.splice(0);
        this.tableColumns.push(...this.columns);
        if (newVal == "NO_DELAY") {
          this.tableColumns.splice(5, 3);
        } else {
          this.tableColumns.splice(4, 1);
        }
      },
    },
  },
  mounted() {
    this.query();
    this.getInstanceList();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        executeType: this.executeType,
      };
      this.loadingTable = true;
      api
        .backupOperationPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              if (!item.status) {
                item.status = "STOP";
              }
              item.enabled = item.status == "WAITING" ? true : false;
              switch (item.status) {
                case "WAITING":
                  item.statusLabel = "等待执行";
                  item.tagColor = "orange";
                  break;
                case "RUNNING":
                  item.statusLabel = "运行中";
                  item.tagColor = "orange";
                  break;
                case "FAILURE":
                  item.statusLabel = "失败";
                  item.tagColor = "red";
                  break;
                case "SUCCESS":
                  item.statusLabel = "成功";
                  item.tagColor = "green";
                  break;
              }
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 新增
    add() {
      this.addData = {
        instanceId: undefined,
        databaseNames: this.executeType == "SCHEDULED" ? [] : undefined,
        extraInfo: {
          drop: false,
          structure: true,
          content: true,
        },
        name: "",
        executeType: this.executeType,
      };
      if (this.executeType == "NO_DELAY") {
        this.filterMode = "INCLUDE";
      }
      this.addKeyVisible = true;
    },
    copy(record) {
      this.getShowDatabases(record.instanceId);
      let data = {
        instanceId: record.instanceId,
        databaseName: record.databaseName,
      };
      this.tableList.splice(0);
      this.selectedTableList.splice(0);
      showTablesForSuper(data).then((res) => {
        if (res.result == 200) {
          res.data.result.forEach((table) => {
            this.tableList.push({ table, key: table });
          });

          let extraInfo = JSON.parse(record.extraInfo);
          this.addData = {
            instanceId: record.instanceId,
            databaseNames: record.databaseName,
            extraInfo: {
              drop: extraInfo.drop,
              structure: extraInfo.structure,
              content: extraInfo.content,
            },
            name: record.databaseName + "_backup_" + moment().format("YYMMDD"),
            executeType: "NO_DELAY",
          };

          this.filterMode = record.filterMode;
          this.selectedTableList.push(...record.selectedTable.split(","));
          this.addKeyVisible = true;
        }
      });
    },
    showTablesForSuper() {
      if (this.executeType == "SCHEDULED") {
        return;
      }
      this.$set(
        this.addData,
        "name",
        this.addData.databaseNames + "_backup_" + moment().format("YYMMDD")
      );
      let data = {
        instanceId: this.addData.instanceId,
        databaseName: this.addData.databaseNames,
      };
      this.tableList.splice(0);
      this.selectedTableList.splice(0);
      showTablesForSuper(data).then((res) => {
        if (res.result == 200) {
          res.data.result.forEach((table) => {
            this.tableList.push({ table, key: table });
          });
        }
      });
    },
    // 确定新增
    add_submit() {
      let data = { ...this.addData };
      if (this.executeType == "SCHEDULED") {
        data.databaseName = data.databaseNames.join(",");
      } else {
        data.databaseName = data.databaseNames;
        data.filterMode = this.filterMode;
        data.tableName = this.selectedTableList.join(",");
      }
      data.extraInfo = JSON.stringify(data.extraInfo);
      delete data.databaseNames;

      api.addBackupOperation(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          if (this.executeType == "SCHEDULED") {
            this.query();
          } else {
            this.backup({ id: res.data[0] });
          }
        }
      });
    },
    selectedTable(val) {
      let data = {
        id: val.id,
      };
      api.tableList(data).then((res) => {
        if (res.result == 200) {
          let selectedTableList = [],
            tableList = [];
          if (val.selectedTable) {
            selectedTableList = val.selectedTable.split(",");
          }
          tableList = res.data;
          this.selectedTableList = selectedTableList;
          this.tableList = tableList.map((table) => {
            return { table, key: table };
          });
          this.backUpId = val.id;
          this.filterMode = val.filterMode;
          this.selectedTableShow = true;
        }
      });
    },
    handleChange(nextTargetKeys) {
      this.selectedTableList = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.tableSelectKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    selectedTableSubmit() {
      let data = {
        id: this.backUpId,
        filterMode: this.filterMode,
        tableName: this.selectedTableList.join(","),
      };
      api.selectedTable(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.selectedTableShow = false;
          this.query();
        }
      });
    },
    policyBackup(val) {
      this.data = val;
      this.$refs.policyBackup.init();
    },
    deleteTemplate(val) {
      let data = {
        id: val.id,
      };
      api.deleteTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 搜索表名
    tableSearch(val) {
      this.getTableList(val);
    },
    // 查询实例
    getInstanceList() {
      listUserInstance().then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 选择实例
    handleInstanceListChange(instanceId) {
      this.getShowDatabases(instanceId);
    },
    // 查询新增账号数据库
    getShowDatabases(instanceId) {
      let data = {
        instanceId,
      };
      showDatabaseForSuper(data).then((res) => {
        if (res.result === 200) {
          this.databaseList = res.data.result;
        }
      });
    },
    refreshSubtaskList() {
      this.refreshSubtaskListTimer = setInterval(() => {
        let data = {
          parentId: this.parentId,
          pageNo: this.subtaskPageNo,
          pageSize: 10,
        };
        api.detailPage(data).then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.fileName =
                item.databaseName + "." + item.fileType.toLowerCase();
              item.timeFormat = this.calculateTimeDifference(
                item.startDate,
                item.endDate
              );
              switch (item.status) {
                case "WAITING":
                  item.statusLabel = "等待执行";
                  item.tagColor = "orange";
                  break;
                case "RUNNING":
                  item.statusLabel = "运行中";
                  item.tagColor = "orange";
                  break;
                case "FAILURE":
                  item.statusLabel = "失败";
                  item.tagColor = "red";
                  break;
                case "SUCCESS":
                  item.statusLabel = "成功";
                  item.tagColor = "green";
                  break;
              }
            });
            this.subtaskTableDataSource = list;
            let runningArr = this.subtaskTableDataSource.filter((item) => {
              return item.status == "RUNNING";
            });
            if (runningArr.length == 0 || !this.subtaskListShow) {
              clearInterval(this.refreshSubtaskListTimer);
              this.refreshSubtaskListTimer = null;
            }
            this.subtaskTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.subtaskList(null, current),
            };
          }
        });
      }, 1000);
    },
    subtaskList(record, index) {
      if (record) {
        this.parentId = record.id;
        this.instanceId = record.instanceId;
        this.databaseName = record.databaseName;
      }
      if (index) {
        this.subtaskPageNo = index;
      }
      let data = {
        parentId: this.parentId,
        pageNo: this.subtaskPageNo,
        pageSize: 10,
      };
      api.detailPage(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.fileName =
              item.databaseName + "." + item.fileType.toLowerCase();
            item.timeFormat = this.calculateTimeDifference(
              item.startDate,
              item.endDate
            );
            switch (item.status) {
              case "WAITING":
                item.statusLabel = "等待执行";
                item.tagColor = "orange";
                break;
              case "RUNNING":
                item.statusLabel = "运行中";
                item.tagColor = "orange";
                break;
              case "FAILURE":
                item.statusLabel = "失败";
                item.tagColor = "red";
                break;
              case "SUCCESS":
                item.statusLabel = "成功";
                item.tagColor = "green";
                break;
            }
          });
          this.subtaskTableDataSource = list;
          let runningArr = this.subtaskTableDataSource.filter((item) => {
            return item.status == "RUNNING";
          });
          if (runningArr.length > 0) {
            this.refreshSubtaskList();
          }
          this.subtaskTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.subtaskList(null, current),
          };
          this.subtaskListShow = true;
        }
      });
    },
    calculateTimeDifference(startDate, endDate) {
      if (startDate == "2000-01-01 00:00:00") {
        return "00:00:00";
      } else if (!endDate || endDate == "2000-01-01 00:00:00") {
        endDate = new Date();
      } else {
        endDate = new Date(endDate);
      }
      startDate = new Date(startDate);

      // 计算差值（毫秒）
      var timeDifference = endDate - startDate;

      // 转换差值为HH:mm:ss格式
      var hours = Math.floor(timeDifference / (1000 * 60 * 60));
      var minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // 格式化输出
      var formattedDifference =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);

      return formattedDifference;
    },
    // 在个位数前面补零
    pad(number) {
      return (number < 10 ? "0" : "") + number;
    },
    backup(record) {
      let data = {
        id: record.id,
      };
      api.backup(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("提交成功");
          this.query();
        }
      });
    },
    modifyFullSyncCronTask(record) {
      this.$confirm({
        title: "确认",
        content: "确认是否" + (record.enabled ? "关闭" : "开启"),
        onOk: () => {
          let data = {
            id: record.id,
            status: record.status == "STOP" ? "WAITING" : "STOP",
          };
          api.modifyFullSyncCronTask(data).then((res) => {
            if (res.result === 200) {
              this.$message.success(
                (record.enabled ? "关闭" : "开启") + "成功"
              );
              this.query();
            }
          });
        },
        onCancel() {},
      });
    },
    recover(val, detailVal) {
      this.ids.splice(0);
      this.databaseInfo = {
        instanceId: undefined,
        databaseName: undefined,
        excludeDatabase: true,
      };
      if (val) {
        this.taskId = val.id;
        this.databaseType = val.databaseType;
        this.databaseInfo = {
          instanceId: this.instanceId,
          databaseName: this.databaseName,
          excludeDatabase: true,
        };
      } else if (detailVal) {
        this.ids.push(detailVal.id);
      } else {
        this.ids.push(...this.selectedRowKeys);
      }

      this.getShowDatabases(this.instanceId);
      this.recoverShow = true;
    },
    recoverSubmit() {
      let data = {
        id: this.taskId,
        ids: this.ids,
        databaseInfo: this.databaseInfo,
      };
      if (this.databaseInfo.instanceId) {
        data.databaseInfo = this.databaseInfo;
      }
      api.recover(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("恢复成功");
          this.recoverShow = false;
          if (this.detailShow) {
            setTimeout(() => {
              this.detailLog();
            }, 1000);
          }
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys.splice(0);
            this.selectedRows.splice(0);
          }
        }
      });
    },
    archive() {
      this.archiveDir = undefined;
      this.getBucketNameList();
    },
    getBucketNameList() {
      api.getBucketNameList().then((res) => {
        if (res.result === 200) {
          this.bucketNameList = res.data;
          this.bucketName = this.bucketNameList[0];
          this.getArchiveDir();
          this.archiveShow = true;
        }
      });
    },
    getArchiveDir() {
      let data = {
        bucketName: this.bucketName,
      };
      api.getArchiveDir(data).then((res) => {
        if (res.result === 200) {
          this.archiveDirList = res.data;
        }
      });
    },
    archiveSubmit() {
      let data = {
        ids: this.selectedRowKeys,
        keyPrefix: "/database/" + this.archiveDir,
        bucketName: this.bucketName,
      };
      this.loading = true;
      api
        .archive(data)
        .then((res) => {
          if (res.result === 200) {
            this.getArchiveStatus(res.data);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getArchiveStatus(uuid) {
      api.archiveStatus({ uuid }).then((res) => {
        if (res.result === 200) {
          let arr = Object.values(res.data);
          if (arr.includes(-1)) {
            setTimeout(() => {
              this.getArchiveStatus(uuid);
            }, 1000);
          } else {
            let archiveErrorCount = 0,
              archiveSuccessCount = 0;
            arr.forEach((item) => {
              switch (item) {
                case 0:
                  archiveErrorCount++;
                  break;
                case 1:
                  archiveSuccessCount++;
                  break;
              }
            });
            this.loading = false;
            this.archiveShow = false;
            this.$message.success(
              "归档已完成,成功" +
                archiveSuccessCount +
                "个,失败" +
                archiveErrorCount +
                "个"
            );
            this.selectedRowKeys.splice(0);
            this.subtaskList();
          }
        }
      });
    },
    // 点击删除
    deleteBackupOperation(val) {
      let data = {
        id: val.id,
      };
      api.deleteBackupOperation(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.subtaskList();
        }
      });
    },
    allowClearDetailLogChange(e) {
      if (e.target.value) {
        return;
      }
      this.detailLog(null, 1);
    },
    refreshDetailLog() {
      this.refreshDetailLogTimer = setInterval(() => {
        let data = {
          taskId: this.taskId,
          pageNo: this.detailPageNo,
          pageSize: this.detailPageSize,
          tableName: this.detailConditionTableName,
        };
        api.backupPlanPage(data).then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.timeFormat = this.calculateTimeDifference(
                item.startDate,
                item.endDate
              );
              item.gmtCreatedMilliSeconds = new Date(item.gmtCreated);
              switch (item.status) {
                case "WAITING":
                  item.statusLabel = "等待执行";
                  item.tagColor = "orange";
                  break;
                case "RUNNING":
                  item.statusLabel = "运行中";
                  item.tagColor = "orange";
                  break;
                case "FAILURE":
                  item.statusLabel = "失败";
                  item.tagColor = "red";
                  break;
                case "SUCCESS":
                  item.statusLabel = "成功";
                  item.tagColor = "green";
                  break;
                case "ARCHIVE":
                  item.statusLabel = "已归档";
                  item.tagColor = "orange";
                  break;
              }
            });
            this.detailTableDataSource = list;
            let runningArr = this.detailTableDataSource.filter((item) => {
              return item.status == "RUNNING";
            });
            if (runningArr.length == 0 || !this.detailShow) {
              clearInterval(this.refreshDetailLogTimer);
              this.refreshDetailLogTimer = null;
            }
            this.detailTablePagination = {
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current, size) => this.detailLog(null, current, size),
              onShowSizeChange: (current, size) =>
                this.detailLog(null, current, size),
            };
          }
        });
      }, 1000);
    },
    detailLog(record, index, size) {
      if (record) {
        this.taskId = record.id;
        this.databaseType = record.databaseType;
        this.detailConditionTableName = "";
      }
      if (index) {
        this.detailPageNo = index;
      }
      if (size) {
        this.detailPageSize = size;
      }
      let data = {
        taskId: this.taskId,
        pageNo: this.detailPageNo,
        pageSize: this.detailPageSize,
        tableName: this.detailConditionTableName,
      };
      api.backupPlanPage(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.timeFormat = this.calculateTimeDifference(
              item.startDate,
              item.endDate
            );
            item.gmtCreatedMilliSeconds = new Date(item.gmtCreated);
            switch (item.status) {
              case "WAITING":
                item.statusLabel = "等待执行";
                item.tagColor = "orange";
                break;
              case "RUNNING":
                item.statusLabel = "运行中";
                item.tagColor = "orange";
                break;
              case "FAILURE":
                item.statusLabel = "失败";
                item.tagColor = "red";
                break;
              case "SUCCESS":
                item.statusLabel = "成功";
                item.tagColor = "green";
                break;
              case "ARCHIVE":
                item.statusLabel = "已归档";
                item.tagColor = "orange";
                break;
            }
          });
          this.detailTableDataSource = list;
          let runningArr = this.detailTableDataSource.filter((item) => {
            return item.status == "RUNNING";
          });
          if (runningArr.length > 0) {
            this.refreshDetailLog();
          }

          this.detailTablePagination = {
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current, size) => this.detailLog(null, current, size),
            onShowSizeChange: (current, size) =>
              this.detailLog(null, current, size),
          };
          this.selectedRowKeys.splice(0);
          this.selectedRows.splice(0);
          this.detailShow = true;
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    batchDownloadTable() {
      this.selectedRows.forEach((item) => {
        if (item.status == "SUCCESS") {
          this.downloadTable(item);
        } else {
          this.$message("只有成功状态可以下载");
        }
      });
      this.selectedRowKeys.splice(0);
      this.selectedRows.splice(0);
    },
    viewDataImportLog(record) {
      if (record) {
        this.recoverId = record.recoverId;
      }
      let data = {
        pageNo: 1,
        pageSize: 10,
        id: this.recoverId,
      };
      dataImportLogList(data).then((res) => {
        if (res.result === 200) {
          this.dataImportLogInit(res.data.records[0]);
          this.viewDataImportLogShow = true;
          this.refreshDataImportLog();
        }
      });
    },
    refreshDataImportLog() {
      this.refreshDataImportLogTimer = setInterval(() => {
        if (this.viewDataImportLogShow == false) {
          clearInterval(this.refreshDataImportLogTimer);
          this.refreshDataImportLogTimer = null;
        }
        let data = {
          pageNo: 1,
          pageSize: 10,
          id: this.recoverId,
        };
        dataImportLogList(data).then((res) => {
          if (res.result === 200) {
            this.dataImportLogInit(res.data.records[0]);
          }
        });
      }, 2000);
    },
    dataImportLogInit(data) {
      let dataImportLog = data;
      dataImportLog.instanceName = this.instanceList.filter((item) => {
        return item.id == dataImportLog.instanceId;
      })[0].name;

      switch (dataImportLog.status) {
        case "UPLOADING":
          dataImportLog.statusLabel = "上传中";
          dataImportLog.tagColor = "orange";
          break;
        case "UPLOADED":
          dataImportLog.statusLabel = "上传成功";
          dataImportLog.tagColor = "green";
          break;
        case "UPLOADFAILURE":
          dataImportLog.statusLabel = "上传失败";
          dataImportLog.tagColor = "red";
          break;
        case "IMPORTING":
          dataImportLog.statusLabel = "导入中";
          dataImportLog.tagColor = "orange";
          break;
        case "WAITING":
          dataImportLog.statusLabel = "等待中";
          dataImportLog.tagColor = "orange";
          break;
        case "FAILURE":
          dataImportLog.statusLabel = "导入失败";
          dataImportLog.tagColor = "red";
          break;
        case "SUCCESS":
          dataImportLog.statusLabel = "导入成功";
          dataImportLog.tagColor = "green";
          break;
      }
      this.dataImportLog = dataImportLog;
    },
    downloadTable(record) {
      location.href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        `/backupOperation/downloadTable?id=${record.id}`;
    },
  },
};
</script>
