import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "../src/lib/antd";
import http from "./lib/axios";
import axios from "axios";
import VueClipboard from "vue-clipboard2";
import VueCodeMirror from "vue-codemirror";
import VueDraggableResizable from "vue-draggable-resizable";
import "codemirror/lib/codemirror.css";
import { UxGrid, UxTableColumn } from "umy-ui";
import "umy-ui/lib/theme-chalk/index.css"; // 引入样式
import contentmenu from "v-contextmenu";
import "v-contextmenu/dist/index.css";

//引入svg组件
import IconSvg from "./components/iconSvg";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3576187_8l4z1luj31.js",
});

import common from "./lib/common";
import Directives from "./directives";

Vue.component("icon-font", IconFont);
// //全局注册svg-icon
Vue.component("svg-icon", IconSvg);
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.use(contentmenu);
Vue.use(UxGrid);
Vue.use(UxTableColumn);
Vue.use(VueClipboard);
Vue.use(VueCodeMirror);
Vue.use(Directives);

Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.prototype.$common = common;

Vue.config.productionTip = false;

Vue.use(antd);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
