<template>
  <div class="databaseTableTransJob">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实 例：
        <a-select
          showSearch
          v-model="instanceId"
          placeholder="可搜索IP地址"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleInstanceListSearch"
          @change="handleInstanceListChange"
          allowClear
        >
          <a-select-option v-for="d in instanceList" :key="d.id">
            {{ d.name }}({{ d.host }}:{{ d.port }})
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名：
        <a-select
          showSearch
          v-model="databaseName"
          placeholder="请选择数据库"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          allowClear
          :disabled="!instanceId"
        >
          <a-select-option
            v-for="item in databaseList"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        表名：
        <a-input
          v-model.trim="tableName"
          placeholder="请输入表名"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
          :disabled="!databaseName"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          v-model="text"
          @change="enabledChange(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="runAtNow(record)">立即执行</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="viewDatabaseTablePreserveLog(record)"
          >查看日志</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 13 }">
        <a-form-item label="选择实例:">
          <a-select
            showSearch
            v-model="instanceIdAdd"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            allowClear
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库名称:">
          <a-select
            showSearch
            v-model="databaseNameAdd"
            placeholder="请选择数据库名称"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            @change="databaseChange"
            :disabled="!instanceIdAdd"
          >
            <a-select-option
              v-for="item in databaseList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="数据库表名:">
          <a-select
            showSearch
            v-model="tableNameAdd"
            placeholder="请选择数据库表名"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="tableSearch"
            mode="multiple"
            :disabled="!databaseNameAdd"
          >
            <a-select-option v-for="d in tableNameList" :key="d.tableName">{{
              d.tableName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="下次执行时间:" v-show="editShow">
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="nextRunTimeAdd"
            placeholder="请选择时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            cron表达式
            <a-tooltip overlayClassName="cronTooltip">
              <template slot="title">
                <img src="../assets/WechatIMG546.png" alt="" />
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="cronInfoAdd" placeholder="请输入cron表达式" />
        </a-form-item>
        <a-form-item label="启用:">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="enabledAdd"
          />
        </a-form-item>
        <a-form-item label="转移类型:">
          <a-select
            v-model="transTypeAdd"
            placeholder="请选择转移类型"
            allowClear
          >
            <a-select-option v-for="item in transTypeList" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="删除原数据:">
          <a-checkbox v-model="cleanDataAdd" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="立即执行"
      v-model="runAtNowShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="执行时间:">
          <a-range-picker
            v-model="runAtNowTime"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            style="width: 250px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="runAtNowShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="runAtNowSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-drawer
      title="维护日志"
      placement="right"
      :visible="viewDatabaseTablePreserveLogShow"
      @close="viewDatabaseTablePreserveLogShow = false"
      width="1000"
    >
      <database-table-preserve-log :jobId="groupId" />
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/databaseTableTransJob.js";
import { showDatabaseForSuper, instanceList } from "../lib/databaseList.js";
import { tableList, enabledOptimize } from "../lib/optimize.js";
import databaseTablePreserveLog from "./databaseTablePreserveLog.vue";

export default {
  components: { databaseTablePreserveLog },
  name: "databaseTableTransJob",
  data() {
    return {
      title: "",
      instanceId: undefined,
      instanceIdAdd: undefined,
      databaseName: undefined,
      databaseNameAdd: undefined,
      tableName: "",
      tableNameAdd: [],
      cronInfo: "",
      cronInfoAdd: "",
      enabled: undefined,
      enabledAdd: undefined,
      transTypeAdd: undefined,
      cleanDataAdd: true,
      enabledList: [],
      groupId: "",
      addKeyVisible: false,
      loading: false,
      nextRunTimeAdd: undefined,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          width: 200,
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          width: 200,
        },
        {
          title: "数据库表名",
          ellipsis: true,
          dataIndex: "tableName",
          width: 200,
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextRunTime",
          width: 170,
        },
        {
          title: "cron表达式",
          ellipsis: true,
          dataIndex: "cronInfo",
          width: 150,
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "转移类型",
          ellipsis: true,
          dataIndex: "transTypeLabel",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      tableDataSource: [],
      editShow: false,
      tablePagination: {},
      loadingTable: false,
      databaseList: [],
      instanceList: [],
      tableNameList: [],
      transTypeList: [
        { label: "按月转移", value: "MONTHLY" },
        { label: "按天转移", value: "DAILY" },
      ],
      runAtNowTime: [],
      runAtNowShow: false,
      viewDatabaseTablePreserveLogShow: false,
    };
  },
  mounted() {
    this.query(1);
    this.handleInstanceListSearch();
  },
  methods: {
    enabledChange(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        enabled: !val.enabled,
      };
      enabledOptimize(data).then((res) => {
        if (res.result === 200) {
          if (val.enabled) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.query();
          this.loadingTable = false;
        }
      });
    },
    databaseChange() {
      this.getTableList();
    },
    handleInstanceListChange(instanceId) {
      this.getShowDatabases({ instanceId });
    },
    // 搜索表名
    tableSearch(val) {
      this.getTableList(val);
    },
    // 查询表格
    getTableList(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        instanceId: this.instanceIdAdd,
        databaseName: this.databaseNameAdd,
        tableName: val,
      };
      tableList(data).then((res) => {
        if (res.result === 200) {
          this.tableNameList = [];
          let list = res.data.records;
          this.tableNameList = list;
        }
      });
    },
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.getInstanceList(data);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            this.instanceList = list;
          }
        }
      });
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabaseForSuper(data).then((res) => {
        if (res.result === 200) {
          this.databaseList = res.data.result;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        databaseName: this.databaseName,
        instanceId: this.instanceId,
        tableName: this.tableName,
        preserveType: "TRANS",
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              item.extraInfo = JSON.parse(item.extraInfo);
              item.transTypeLabel = this.transTypeList.filter((type) => {
                return type.value == item.extraInfo.transType;
              })[0].label;
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.editShow = true;
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.instanceIdAdd = val.instanceId;
      this.databaseNameAdd = val.databaseName;
      this.tableNameAdd.splice(0);
      this.tableNameAdd.push(val.tableName);
      this.cronInfoAdd = val.cronInfo;
      this.enabledAdd = val.enabled;
      this.transTypeAdd = val.extraInfo.transType;
      if (val.extraInfo.cleanData) {
        this.cleanDataAdd = val.extraInfo.cleanData;
      } else {
        this.cleanDataAdd = false;
      }
      this.nextRunTimeAdd = val.nextRunTime;
    },
    // 新增
    add() {
      this.title = "新增";
      this.editShow = false;
      this.addKeyVisible = true;
      this.instanceIdAdd = undefined;
      this.databaseNameAdd = undefined;
      this.tableNameAdd.splice(0);
      this.cronInfoAdd = "0 0 0 * * *";
      this.enabledAdd = true;
      this.transTypeAdd = undefined;
      this.cleanDataAdd = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        instanceId: this.instanceIdAdd,
        databaseName: this.databaseNameAdd,
        tableNameList: this.tableNameAdd,
        cronInfo: this.cronInfoAdd,
        enabled: this.enabledAdd,
        extraInfo: JSON.stringify({
          transType: this.transTypeAdd,
          cleanData: this.cleanDataAdd,
        }),
      };
      if (this.title === "新增") {
        data.preserveType = "TRANS";
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        data.nextRunTime = this.nextRunTimeAdd;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    runAtNow(val) {
      this.groupId = val.id;
      this.runAtNowTime = [];
      this.runAtNowShow = true;
    },
    runAtNowSubmit() {
      if (
        this.runAtNowTime.length == 0 ||
        !this.runAtNowTime[0] ||
        !this.runAtNowTime[1]
      ) {
        this.$message.warning("请选择时间");
        return;
      }
      let data = {
        id: this.groupId,
        start: this.runAtNowTime[0],
        end: this.runAtNowTime[1],
      };
      api.runAtNow(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("执行成功");
          this.runAtNowShow = false;
          this.query();
        }
      });
    },
    viewDatabaseTablePreserveLog(val) {
      this.groupId = val.id;
      this.viewDatabaseTablePreserveLogShow = true;
    },
  },
};
</script>
