<template>
  <div class="permissionList">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="数据库授权">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="SQL系统授权">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "permissionList",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/databaseList/permissionList/userAccountRelation") {
          this.active = "1";
        } else if (newVal == "/databaseList/permissionList/userSqlPermission") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (
            this.$route.path ==
            "/databaseList/permissionList/userAccountRelation"
          )
            return;
          this.$router.replace(
            "/databaseList/permissionList/userAccountRelation"
          );
        } else if (newVal === "2") {
          if (
            this.$route.path == "/databaseList/permissionList/userSqlPermission"
          )
            return;
          this.$router.replace(
            "/databaseList/permissionList/userSqlPermission"
          );
        }
      },
    },
  },
};
</script>
