import http from "./axios";

// 登录用户信息
export const userInfo = () => {
  return http.get("/alpha/user/info");
};

// 退出登录
export const userLogout = () => {
  return http.get("/alpha/user/logout");
};

// 执行sql查询
export const sqlExecute = (data) => {
  return http.json_post("/crudOperation/executeAsync", data);
};

export const fetchAsyncJobResult = (data, cancelToken) => {
  return http.get("/asyncSqlRunJob/fetchAsyncJobResult", data, cancelToken);
};

export const listAsyncSqlJob = (data) => {
  return http.get("/asyncSqlRunJob/listAsyncSqlJob", data);
};

export const change2BackgroudRun = (data) => {
  return http.json_post("/asyncSqlRunJob/change2BackgroudRun", data);
};

export const tryKillProcess = (data) => {
  return http.json_post("/asyncSqlRunJob/tryKillProcess", data);
};

// 查询表信息
export const tableInfo = (data) => {
  return http.get("/db/sql/tableInfo", data);
};

export const updateOneRow = (data) => {
  return http.json_post("/crudOperation/updateOneRow", data);
};

export const deleteOneRow = (data) => {
  return http.json_post("/crudOperation/deleteOneRow", data);
};

export const insertOneRow = (data) => {
  return http.json_post("/crudOperation/insertOneRow", data);
};

// 查询导出文件
export const generateFile = (data) => {
  return http.json_post("/dumpOperation/generateFile", data);
};

export const listUserInstance = () => {
  return http.get("/db/sql/listUserInstance");
};

export const listUserDatabase = (data) => {
  return http.get("/db/sql/listUserDatabase", data);
};

export const listUserAccount = (data) => {
  return http.get("/userAccountRelation/listUserAccount", data);
};

// 查询树形图
export const showTables = (data) => {
  return http.get("/databaseOperation/showTables", data);
};

export const createTable = (data) => {
  return http.json_post("/tableOperation/createTable", data);
};

export const copyTable = (data) => {
  return http.json_post("/tableOperation/copyTable", data);
};

export const truncateTable = (data) => {
  return http.json_post("/tableOperation/truncateTable", data);
};

export const dropTable = (data) => {
  return http.json_post("/tableOperation/dropTable", data);
};

export const renameTable = (data) => {
  return http.json_post("/tableOperation/renameTable", data);
};

// 查询导入记录
export const dataImportLogList = (data) => {
  return http.get("/dumpOperation/dataImportLogList", data);
};

// 查询导出记录
export const dataDumpLogList = (data) => {
  return http.get("/db/dataDump/dataDumpLogList", data);
};

// 查询预览导入数据
export const dataImportPreview = (data) => {
  return http.get("/dumpOperation/preview", data);
};

export const updateImport = (data) => {
  return http.json_post("/dumpOperation/updateImport", data);
};

export const getUserDatabaseInstanceConfig = (data) => {
  return http.get("/db/instance/getUserDatabaseInstanceConfig", data);
};

export const updateUserDatabaseInstanceConfig = (data) => {
  return http.json_post("/db/instance/updateUserDatabaseInstanceConfig", data);
};

export const refreshDatabaseConfig = (data) => {
  return http.json_post("/db/sql/refreshDatabaseConfig", data);
};

export const addSqlAudit = (data) => {
  return http.json_post("/db/sql/addSqlAudit", data);
};

export const memoList = (data) => {
  return http.get("/delivery/memo/list", data);
};

export const memoAddMemo = (data) => {
  return http.json_post("/delivery/memo/addMemo", data);
};

export const memoDeleteMemo = (data) => {
  return http.json_post("/delivery/memo/deleteMemo", data);
};

export const memoUpdateMemo = (data) => {
  return http.json_post("/delivery/memo/updateMemo", data);
};

export const loadChangedCache = (data) => {
  return http.get("/cache/loadChangedCache", data);
};

export const uploadLocalCache = (data) => {
  return http.json_post("/cache/uploadLocalCache", data);
};

export const showTableContent = (data, token) => {
  return http.get("/tableOperation/showTableContent", data, token);
};

export const showCreateTableSql = (data) => {
  return http.get("/tableOperation/showCreateTableSql", data);
};

export const showTableStructure = (data, token) => {
  return http.get("/tableOperation/showTableStructure", data, token);
};

export const deleteTableColumn = (data) => {
  return http.json_post("/tableOperation/deleteTableColumn", data);
};

export const insertTableColumn = (data) => {
  return http.json_post("/tableOperation/insertTableColumn", data);
};

export const updateTableColumn = (data) => {
  return http.json_post("/tableOperation/updateTableColumn", data);
};

export const fetchResult = (data) => {
  return http.get("/db/sql/fetchResult", data);
};

export const showIndex = (data, token) => {
  return http.get("/indexOperation/showIndex", data, token);
};

export const createIndex = (data) => {
  return http.json_post("/indexOperation/createIndex", data);
};

export const dropIndex = (data) => {
  return http.json_post("/indexOperation/dropIndex", data);
};

export const modifyIndex = (data) => {
  return http.json_post("/indexOperation/modifyIndex", data);
};

export const dumpOperationImport = (data) => {
  return http.get("/dumpOperation/import", data);
};

export const backup = (data) => {
  return http.json_post("/backupOperation/backup", data);
};

export const updateImportDatabaseSource = (data) => {
  return http.json_post("/dumpOperation/updateImportDatabaseSource", data);
};

export const check = (data) => {
  return http.json_post("/crudOperation/check", data);
};

export const showProcesslist = (data) => {
  return http.get("/databaseOperation/showProcesslist", data);
};

export const killProcess = (data) => {
  return http.json_post("/databaseOperation/killProcess", data);
};

export const getTableRows = (data) => {
  return http.get("/tableOperation/getTableRows", data);
};

export const showHelp = (data) => {
  return http.get("/db/sql/showHelp", data);
};
