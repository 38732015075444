import http from "./axios";

export const listPermissionPage = (data) => {
  return http.get("/userPermission/listPermissionPage", data);
};

export const listPermission = (data) => {
  return http.get("/userPermission/listPermission", data);
};

export const listUserPermission = (data) => {
  return http.get("/userPermission/listUserPermission", data);
};

export const grantPermissionToUser = (data) => {
  return http.json_post("/userPermission/grantPermissionToUser", data);
};

export const revokePermissionFromUser = (data) => {
  return http.json_post("/userPermission/revokePermissionFromUser", data);
};
