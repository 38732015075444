<template>
  <div class="userSqlLogList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实例：
        <a-select
          showSearch
          :value="instanceId"
          placeholder="可搜索IP地址"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleInstanceListSearch"
          @change="handleInstanceListChange"
          :notFoundContent="fetching ? undefined : null"
          allowClear
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in instanceList" :key="d.value">{{
            d.label
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名：
        <a-select
          v-model="databaseName"
          placeholder="请选择数据库名称"
          style="width: 240px"
          allowClear
          :disabled="!instanceId"
        >
          <a-select-option
            v-for="item in databaseList"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        执行时间：
        <a-date-picker
          :disabledDate="disabledLastLoginStartDate"
          format="YYYY-MM-DD"
          v-model="lastLoginDateStart"
          placeholder="开始日期"
          @openChange="handleLastLoginStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledLastLoginEndDate"
          format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="lastLoginDateEnd"
          :open="loginEndOpen"
          @openChange="handleLastLoginEndOpenChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(sqlColumns)"
      :columns="sqlColumns"
      :dataSource="sqlDataSource"
      :pagination="sqlPagination"
      size="small"
    >
      <span slot="cost" slot-scope="text, record" style="width: 100%">
        {{ timeConsuming(record.cost) }}
      </span>
      <span slot="statusLabel" slot-scope="text, record" style="width: 100%">
        <a-tag :color="record.color"
          >{{ text }}
          <a-icon v-if="text === '执行中'" type="loading" />
        </a-tag>
      </span>
      <span slot="databaseName" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title">{{ record.instanceAddress }}</template>
          <span>{{ text }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="checkSql(record)">查看SQL</a>
      </span>
    </a-table>
    <!-- 查看SQL -->
    <a-modal
      title="查看SQL"
      v-model="checkSqlShow"
      :maskClosable="false"
      :afterClose="checkSqlClose"
      width="600px"
    >
      <a-textarea
        v-model="checkSqlContent"
        placeholder="SQL"
        :auto-size="{ minRows: 25, maxRows: 25 }"
      ></a-textarea>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="checkSqlShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/userSqlLogList.js";
import { showDatabaseForSuper, instanceList } from "../lib/databaseList.js";
import moment from "moment";

export default {
  name: "userSqlLogList",
  data() {
    return {
      lastLoginDateStart: null,
      lastLoginDateEnd: null,
      loginEndOpen: false,
      checkSqlShow: false,
      checkSqlContent: "",
      sqlColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          scopedSlots: { customRender: "databaseName" },
          width: 120,
        },
        {
          title: "表名称",
          ellipsis: true,
          dataIndex: "tableName",
          width: 120,
        },
        {
          title: "执行sql",
          ellipsis: true,
          dataIndex: "content",
          width: 400,
        },
        {
          title: "影响行数",
          ellipsis: true,
          dataIndex: "affectCount",
          width: 100,
        },
        {
          title: "执行人",
          ellipsis: true,
          dataIndex: "userName",
          width: 120,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "statusLabel" },
          width: 150,
        },
        {
          title: "耗时(ms)",
          ellipsis: true,
          dataIndex: "cost",
          scopedSlots: { customRender: "cost" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      sqlDataSource: [],
      sqlPagination: {},
      pageNo: 1,
      instanceId: undefined,
      databaseName: undefined,
      gmtEndOpen: false,
      fetching: false,
      instanceList: [],
      databaseList: [],
      timer: null,
    };
  },
  computed: {
    lastLoginDateEndTime() {
      // 结束时间
      if (this.lastLoginDateEnd) {
        return this.$common.transformTime(this.lastLoginDateEnd);
      }
      return null;
    },
    lastLoginDateStartTime() {
      // 开始时间
      if (this.lastLoginDateStart) {
        return this.$common.transformTime(this.lastLoginDateStart);
      }
      return null;
    },
    timeConsuming() {
      return (cost) => {
        let seconds = Math.floor(cost / 1000);
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;

        // 补零函数，使得数字始终为两位数
        const pad = (num) => (num < 10 ? "0" + num : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
      };
    },
  },
  mounted() {
    let nowdate = new Date();
    let startTime = new Date(nowdate - 3 * 24 * 3600 * 1000);
    startTime.setHours(0);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date();
    endTime.setHours(23);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    endTime.setMilliseconds(59);
    this.lastLoginDateStart = moment(startTime, "YYYY-MM-DD HH:mm:ss");
    this.lastLoginDateEnd = moment(endTime, "YYYY-MM-DD HH:mm:ss");
    this.query(1);
    this.getInstanceList({ pageNo: 1, pageSize: 1000 });
  },
  methods: {
    userSqlLogRefresh(idList) {
      api.userSqlLogRefresh({ idList }).then((res) => {
        if (res.result == 200) {
          let idList = [];
          res.data.forEach((newItem) => {
            if (
              newItem.status == "EXECUTING" ||
              newItem.status == "WAIT_EXECUTE"
            ) {
              idList.push(newItem.id);
            }
            this.sqlDataSource.forEach((item, index) => {
              if (newItem.id == item.id) {
                this.$set(item, "status", newItem.status);
                this.$set(item, "cost", newItem.cost);
                let statusLabel = "",
                  color = "";
                switch (item.status) {
                  case "SUCCESS":
                    statusLabel = "执行成功";
                    color = "green";
                    break;
                  case "EXCEPTION":
                    statusLabel = "执行异常";
                    color = "red";
                    break;
                  case "FAILURE":
                    statusLabel = "执行失败";
                    color = "red";
                    break;
                  case "WAIT_EXECUTE":
                    statusLabel = "等待执行";
                    color = "orange";
                    break;
                  case "EXECUTING":
                    statusLabel = "执行中";
                    color = "orange";
                    break;
                  case "PART_FAILURE":
                    statusLabel = "部分失败";
                    color = "red";
                    break;
                }
                this.$set(item, "statusLabel", statusLabel);
                this.$set(item, "color", color);
              }
            });
          });
          if (idList.length > 0) {
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
            }
            this.timer = setTimeout(() => {
              this.userSqlLogRefresh(idList);
            }, 2000);
          }
        }
      });
    },
    // 不可选的登录日期
    disabledLastLoginStartDate(startValue) {
      const endValue = this.lastLoginDateEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledLastLoginEndDate(endValue) {
      const startValue = this.lastLoginDateStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择登录日期
    handleLastLoginStartOpenChange(open) {
      if (!open) {
        this.loginEndOpen = true;
      }
    },
    handleLastLoginEndOpenChange(open) {
      this.loginEndOpen = open;
    },
    // 点击查看sql
    checkSql(val) {
      this.checkSqlShow = true;
      this.checkSqlContent = val.content;
    },
    // 关闭查看sql
    checkSqlClose() {
      this.checkSqlContent = "";
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        instanceId: this.instanceId,
        databaseName: this.databaseName,
        gmtCreatedStart: this.lastLoginDateStartTime,
        gmtCreatedEnd: this.lastLoginDateEndTime,
      };
      this.getUserSqlLogList(data);
    },
    // 查询sql执行列表
    getUserSqlLogList(data) {
      api.userSqlLogList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.executeTime = this.$common.transformTime(item.executeTime);
            switch (item.status) {
              case "SUCCESS":
                item.statusLabel = "执行成功";
                item.color = "green";
                break;
              case "EXCEPTION":
                item.statusLabel = "执行异常";
                item.color = "red";
                break;
              case "FAILURE":
                item.statusLabel = "执行失败";
                item.color = "red";
                break;
              case "WAIT_EXECUTE":
                item.statusLabel = "等待执行";
                item.color = "orange";
                break;
              case "EXECUTING":
                item.statusLabel = "执行中";
                item.color = "orange";
                break;
              case "PART_FAILURE":
                item.statusLabel = "部分失败";
                item.color = "red";
                break;
            }
          });
          this.sqlDataSource.splice(0);
          this.sqlDataSource.push(...list);
          let idList = [];
          this.sqlDataSource.forEach((item) => {
            if (item.status == "EXECUTING" || item.status == "WAIT_EXECUTE") {
              idList.push(item.id);
            }
          });
          if (idList.length > 0) {
            this.userSqlLogRefresh(idList);
          }
          this.sqlPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.fetching = true;
      this.getInstanceList(data);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            list.forEach((item) => {
              let data = {
                label: item.host + ":" + item.port,
                value: item.id,
              };
              this.instanceList.push(data);
            });
            this.fetching = false;
          }
        }
      });
    },
    // 选择实例
    handleInstanceListChange(instanceId) {
      this.instanceId = instanceId;
      this.databaseName = undefined;
      if (instanceId) {
        this.getShowDatabases({ instanceId });
      } else {
        this.databaseList = [];
      }
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabaseForSuper(data).then((res) => {
        if (res.result === 200) {
          this.databaseList = res.data.result;
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>
