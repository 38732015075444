import http from "./axios";

export const listUserAccountRelation = (data) => {
  return http.get("/userAccountRelation/listUserAccountRelation", data);
};

export const grant2User = (data) => {
  return http.json_post("/userAccountRelation/grant2User", data);
};

export const revokeFromUser = (data) => {
  return http.json_post("/userAccountRelation/revokeFromUser", data);
};
