<template>
  <div class="userAccountRelation">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="searchCondition.userLoginName"
          placeholder="搜索用户"
          showSearch
          :filterOption="false"
          @search="getUserNameList"
          @change="query(1)"
          allowClear
        >
          <a-select-option
            v-for="item in userNameList"
            :key="item.userLoginName"
            >{{ item.userName }}({{ item.userLoginName }})</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        实例：
        <a-select
          v-model="searchCondition.instanceId"
          placeholder="可搜索IP地址"
          showSearch
          :filterOption="false"
          @search="getInstanceList"
          @change="query(1)"
          allowClear
        >
          <a-select-option
            v-for="item in instanceList"
            :key="item.id"
            :value="item.id"
            >{{ item.host + ":" + item.port }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">添加授权</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="user" slot-scope="text, record">
        {{ text }}@{{ record.allowHost }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认撤销授权{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">撤销授权</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="添加授权"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="用户:">
          <a-select
            v-model="updateData.userLoginName"
            placeholder="搜索用户"
            showSearch
            :filterOption="false"
            @search="getUserNameList"
          >
            <a-select-option
              v-for="item in userNameList"
              :key="item.userLoginName"
              >{{ item.userName }}({{ item.userLoginName }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="实例:">
          <a-select
            v-model="updateData.instanceId"
            placeholder="可搜索IP地址"
            showSearch
            :filterOption="false"
            @search="getInstanceList"
            @change="getAccountList"
          >
            <a-select-option
              v-for="item in instanceList"
              :key="item.id"
              :value="item.id"
              >{{ item.host + ":" + item.port }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="账号:">
          <a-select
            :disabled="!updateData.instanceId"
            v-model="updateData.accountExtraInfoId"
            placeholder="可搜索"
            showSearch
            option-filter-prop="children"
          >
            <a-select-option
              v-for="item in accountList"
              :key="item.accountExtraInfoId"
              :value="item.accountExtraInfoId"
              :disabled="!item.accountExtraInfo"
              >{{ item.user + "@" + item.allowHost }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="授权过期时间:">
          <a-switch
            un-checked-children="永不过期"
            checked-children="定时过期"
            v-model="expireTimeShow"
            @change="expireTimeShowChange"
            class="expireTimeSwitch"
          />
          <a-input
            v-model="expireTime"
            v-if="expireTimeShow"
            placeholder="请输入过期时间"
            style="width: 200px; margin-left: 5px"
          >
            <a-select
              slot="addonAfter"
              v-model="expireUnit"
              style="width: 65px"
            >
              <a-select-option value="月"> 月 </a-select-option>
              <a-select-option value="天"> 天 </a-select-option>
              <a-select-option value="小时"> 小时 </a-select-option>
              <a-select-option value="分钟"> 分钟 </a-select-option>
              <a-select-option value="秒"> 秒 </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/userAccountRelation.js";
import { instanceList, sqlUerList, listAccount } from "../lib/databaseList.js";

export default {
  name: "userAccountRelation",
  data() {
    return {
      searchCondition: {
        userLoginName: undefined,
        instanceId: undefined,
      },
      instanceList: [],
      accountList: [],
      userNameList: [],
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "实例名称",
          dataIndex: "instanceName",
          ellipsis: true,
          width: 200,
        },
        {
          title: "用户",
          dataIndex: "userLoginName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "账号",
          dataIndex: "user",
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "授权过期时间",
          dataIndex: "expireAt",
          ellipsis: true,
          width: 170,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      expireTimeShow: false,
      expireUnit: "天",
      expireTime: 1,
    };
  },
  mounted() {
    this.getInstanceList();
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ...this.searchCondition,
      };
      this.loadingTable = true;
      api
        .listUserAccountRelation(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 查询实例
    getInstanceList(host) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host,
      };
      instanceList(data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.instanceList = res.data.records;
          }
        }
      });
    },
    getAccountList() {
      if (!this.updateData.instanceId) {
        return;
      }
      let data = {
        instanceId: this.updateData.instanceId,
      };
      listAccount(data).then((res) => {
        if (res.result == 200) {
          this.accountList = res.data;
          this.accountList.forEach((item) => {
            if (item.accountExtraInfo) {
              this.$set(item, "accountExtraInfoId", item.accountExtraInfo.id);
            }
          });
        }
      });
    },
    // 查询用户列表
    getUserNameList(username) {
      let data = {
        username,
      };
      sqlUerList(data).then((res) => {
        if (res.result === 200) {
          this.userNameList = res.data;
        }
      });
    },
    // 新增
    add() {
      this.addKeyVisible = true;
      this.expireTimeShow = false;
      this.updateData = {
        userLoginName: undefined,
        instanceId: undefined,
        accountExtraInfoId: undefined,
      };
    },
    expireTimeShowChange() {
      if (this.expireTimeShow) {
        this.expireTime = 1;
        this.expireUnit = "天";
      }
    },
    add_submit() {
      let data = { ...this.updateData };
      delete data.instanceId;
      if (this.expireTimeShow) {
        const unitsInSeconds = {
          月: 30 * 24 * 60 * 60, // 假设每月按30天算
          天: 24 * 60 * 60,
          小时: 60 * 60,
          分钟: 60,
          秒: 1,
        };

        data.expireSeconds =
          this.expireTime * 1 * unitsInSeconds[this.expireUnit];
      } else {
        data.expireSeconds = -1;
      }

      this.loading = true;
      api.grant2User(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.loading = false;
          this.$message.success("添加授权成功");
          this.query(1);
        }
      });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.revokeFromUser(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("撤销授权成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.expireTimeSwitch {
  background-color: #ff8000 !important;
}
:deep() .ant-switch-checked {
  background-color: #1890ff !important;
}
</style>
